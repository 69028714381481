import { gql } from '@apollo/react-hooks';
import { AppFileFragment } from './fragments/appfile';
import { FileInfo } from '../types/parse';
import { GQLAppFilePointerInput } from '../graphql.schema';

export interface CreateFileRes {
  createAppFile: {
    appFile: { file: FileInfo; objectId: string };
  };
}

export interface CreateFileParams extends GQLAppFilePointerInput {}

export const CreateAppFileQuery = gql`
  mutation createAppFile($upload: Upload) {
    createAppFile(input: { fields: { file: { upload: $upload } } }) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface UpdateFileRes {
  UpdateAppFile: {
    appFile: { file: FileInfo; objectId: string };
  };
}

export interface UpdateFileParams {
  upload: File;
  id: string;
}

export const UpdateAppFileQuery = gql`
  mutation updateAppFile($id: ID!, $upload: Upload) {
    updateAppFile(input: { id: $id, fields: { file: { upload: $upload } } }) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;
