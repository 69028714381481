import React from 'react';
import { EventForm } from '../../components/Events/EventForm';
import { FormModes } from '../../constants/common';
import { useDeleteEvent, useGetEvent } from '../../hooks/events';
import { useGetProjectsList } from '../../hooks/projects';

export const ViewEvent = () => {
  const { data, loading, refetch } = useGetEvent();
  const { deleteEvent } = useDeleteEvent({
    onsuccessCb: () => refetch(),
  });
  const { data: projectsData, loading: projectsLoading } = useGetProjectsList('');

  return (
    <EventForm
      mode={FormModes.View}
      item={data}
      loading={loading || projectsLoading}
      deleteEvent={deleteEvent}
      projects={projectsData}
    />
  );
};
