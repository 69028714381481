import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { message } from 'antd';
import {
  AddTechnologyParams,
  AddTechnologyQuery,
  AddTechnologyRes,
  DeleteTechnologyParams,
  DeleteTechnologyQuery,
  DeleteTechnologyRes,
  EditTechnologyParams,
  EditTechnologyQuery,
  EditTechnologyRes,
  GetTechnologiesParams,
  GetTechnologiesQuery,
  GetTechnologiesRes,
  GetTechnologyParams,
  GetTechnologyQuery,
  GetTechnologyRes,
} from '../queries/technologies';
import {
  CreateAppFileQuery,
  CreateFileParams,
  CreateFileRes,
  UpdateAppFileQuery,
  UpdateFileParams,
  UpdateFileRes,
} from '../queries/appfile';
import { AppFile, LoadFile } from '../types/common';
import { order } from '../constants/common';
import { GQLAppFilePointerInput } from '../graphql.schema';
import { changeFileName } from '../helpers/common';

export const useGetTechnologiesList = () => {
  const { data, ...otherData } = useQuery<GetTechnologiesRes, GetTechnologiesParams>(GetTechnologiesQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      order: [order.layOut],
    },
  });

  const _data = useMemo(() => data?.technologies.edges.map((el) => el.node), [data]);

  return { data: _data, ...otherData };
};

export const useGetTechnology = () => {
  const { id } = useParams<{ id: string }>();
  const { data, ...otherData } = useQuery<GetTechnologyRes, GetTechnologyParams>(GetTechnologyQuery, {
    variables: { id: id || '' },
  });

  return { data: data?.technology, ...otherData };
};

export type AddTechnologyFnParams = Partial<Omit<AddTechnologyParams['fields'], 'logo'> & { logo: LoadFile }>;

export const useAddTechnology = (options?: { cb?: (id: string) => void }) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddTechnologyRes, AddTechnologyParams>(AddTechnologyQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);

  const addTechnology = async (_params: AddTechnologyFnParams) => {
    const { logo, ...params } = _params;

    try {
      let logoInfo: AppFile | undefined;

      if (logo) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(logo as File),
          } as GQLAppFilePointerInput,
        });

        logoInfo = response.data?.createAppFile.appFile;
      }

      const { data } = await add({
        variables: {
          fields: {
            ...params,
            ...(logoInfo?.objectId
              ? {
                  logo: {
                    link: logoInfo.objectId,
                  },
                }
              : {}),
          },
        },
      });

      const id = data?.createTechnology.technology.objectId;
      if (id) cb?.(id);
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { addTechnology, ...otherData };
};

export type EditTechnologyFnParams = Omit<EditTechnologyParams['fields'], 'logo'> & {
  logo?: { file: LoadFile; id?: string };
  id: string;
};

export const useEditTechnology = () => {
  const [edit, otherData] = useMutation<EditTechnologyRes, EditTechnologyParams>(EditTechnologyQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);
  const [updateAppFile] = useMutation<UpdateFileRes, UpdateFileParams>(UpdateAppFileQuery);

  const editTechnology = async (_params: EditTechnologyFnParams) => {
    const { logo, id: techId, ...params } = _params;
    const { id, file } = logo || {};

    try {
      let logoInfo: AppFile | undefined;

      if (file && !id) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(file as File),
          } as GQLAppFilePointerInput,
        });

        logoInfo = response.data?.createAppFile.appFile;
      } else if (file && id) {
        const response = await updateAppFile({
          variables: {
            upload: changeFileName(file as File),
            id,
          },
        });

        logoInfo = response.data?.UpdateAppFile?.appFile;
      }

      await edit({
        variables: {
          id: techId,
          fields: {
            ...params,
            ...(logoInfo?.objectId || file === null
              ? {
                  logo: {
                    link: logoInfo?.objectId || null,
                  },
                }
              : {}),
          },
        },
      });
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editTechnology, ...otherData };
};

export const useDeleteTechnology = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeleteTechnologyRes, DeleteTechnologyParams>(DeleteTechnologyQuery);

  const deleteTechnology = async (params: DeleteTechnologyParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deleteTechnology, otherData };
};
