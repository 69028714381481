import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ListContainer } from '../common/ListContainer';
import { AddButton } from '../common/ActionsButtons';
import { routes } from '../../constants/routes';
import { Teammate } from '../../types/team';
import { DeleteTeamParams } from '../../queries/teams';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { RearrangeData } from '../../hooks/list';
import { DragSortingTable } from '../Table';
import { ItemType } from '../../types/common';

interface IProps {
  isLoading?: boolean;
  deleteTeam: (params: DeleteTeamParams) => void;
  rearrangeData: RearrangeData<Teammate>;
  setValue: Dispatch<SetStateAction<string>>;
}

export const Team: React.FC<IProps> = ({ isLoading, deleteTeam, rearrangeData, setValue }) => {
  const { items, moveUp, moveDown, saveOrder } = rearrangeData;
  const [isSave, setIsSave] = useState(0);

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('team'), () => deleteTeam({ id }));
  };
  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  useEffect(() => {
    if (!isSave || !items.length) return;
    saveOrder();
  }, [isSave]);

  const extras = [<AddButton key={Math.random()} to={routes.addTeammate} />];

  const options = items?.map((item) => ({ value: item.firstName }));

  return (
    <>
      <ListContainer title="Team" extra={extras} search options={options} setValue={setValue}>
        <DragSortingTable
          view={routes.viewTeammate}
          edit={routes.editTeammate}
          onMoveUp={handleMoveUp}
          onMoveDown={handleMoveDown}
          items={items as ItemType[]}
          onDelete={handleDelete}
          loading={!!isLoading}
          saveOrder={setIsSave}
        />
      </ListContainer>
    </>
  );
};
