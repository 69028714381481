import React from 'react';
import { EventForm } from '../../components/Events/EventForm';
import { FormModes } from '../../constants/common';
import { useAddEvent, useGetEventsList } from '../../hooks/events';
import { useGetProjectsList } from '../../hooks/projects';

export const AddEvent = () => {
  const { refetch } = useGetEventsList();
  const { addEvent } = useAddEvent({ cb: () => refetch() });
  const { data } = useGetProjectsList('');

  return <EventForm mode={FormModes.Add} addEvent={addEvent} projects={data} />;
};
