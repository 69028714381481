import { gql } from '@apollo/react-hooks';
import { ItemType } from '../constants/common';

export interface ChangeOrderRes {
  listRearrange: {
    __typename: string;
  };
}
export interface ChangeOrderParams {
  className: ItemType;
  list: Array<string>;
}

export const ChangeOrderQuery = gql`
  mutation changeOrder($className: Classes!, $list: [String]!) {
    listRearrange(input: { className: $className, list: $list })
  }
`;
