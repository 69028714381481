import React from 'react';
import { FormModes } from '../../constants/common';
import { PartnerForm } from '../../components/Partners';
import { useAddPartner, useGetPartnersList } from '../../hooks/partners';

export const AddPartner = () => {
  const { refetch } = useGetPartnersList();
  const { addPartner } = useAddPartner({ cb: () => refetch() });

  return <PartnerForm addPartner={addPartner} mode={FormModes.Add} />;
};
