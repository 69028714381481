import React from 'react';
import { ApolloLink, ApolloProvider as Provider, from, InMemoryCache, ApolloClient } from '@apollo/react-hooks';
import { DefaultOptions } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { API_KEY, APPLICATION_ID, GRAPHQL_URI } from './constants/env';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
    fetchPolicy: 'network-only',
  },
};

const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
  headers: {
    'X-Parse-Application-Id': APPLICATION_ID,
    'X-Parse-REST-API-Key': API_KEY,
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  if (!token) return headers;
  return {
    headers: {
      ...headers,
      'X-Parse-Session-Token': token,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink as unknown as ApolloLink, httpLink]),
});

client.defaultOptions = defaultOptions;

export const ApolloProvider: React.FC = ({ children }) => <Provider client={client}>{children}</Provider>;
