import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { BaseButton } from './BaseButton';
import { ActionButton } from './types';

const Button = styled(BaseButton)`
  background-color: #7cb305;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;

  &:hover {
    background-color: #a0d911;
  }
`;

export const AddButton: React.FC<ActionButton> = (props) => {
  return (
    <Button icon={<PlusOutlined />} type="primary" {...props}>
      add
    </Button>
  );
};
