import { gql } from '@apollo/react-hooks';
import { Connection } from '../types/parse';
import { Partner } from '../types/partners';
import { PartnerFragment } from './fragments/partner';

export interface GetPartnersListRes {
  partners: Connection<Partner>;
}
export interface GetPartnersListParams {
  order?: Array<string>;
}

export const GetPartnersListQuery = gql`
  query partners($order: [PartnerOrder!]) {
    partners(order: $order) {
      edges {
        node {
          ...PartnerFragment
        }
      }
    }
  }
  ${PartnerFragment}
`;

export interface AddPartnerParams {
  fields: {
    name?: string;
    descr?: string;
    logo?: {
      link: string;
    };
    showOnMainPage?: boolean;
    website?: string;
    alias?: string;
    titleColor?: string;
  };
}

export interface AddPartnerRes {
  createPartner: {
    partner: Partner;
  };
}

export const AddPartnerQuery = gql`
  mutation createPartner($fields: CreatePartnerFieldsInput) {
    createPartner(input: { fields: $fields }) {
      partner {
        ...PartnerFragment
      }
    }
  }
  ${PartnerFragment}
`;

export interface DeletePartnerParams {
  id: string;
}

export interface DeletePartnerRes {
  deletePartner: {
    partner: Partner;
  };
}

export const DeletePartnerQuery = gql`
  mutation deletePartner($id: ID!) {
    deletePartner(input: { id: $id }) {
      partner {
        ...PartnerFragment
      }
    }
  }
  ${PartnerFragment}
`;

export interface GetPartnerParams {
  id: string;
}

export interface GetPartnerRes {
  partner: Partner;
}

export const GetPartnerQuery = gql`
  query partner($id: ID!) {
    partner(id: $id) {
      ...PartnerFragment
    }
  }
  ${PartnerFragment}
`;

export interface EditPartnerParams {
  id?: string;
  fields: {
    name?: string;
    descr?: string;
    logo?: {
      link: string;
    };
    showOnMainPage?: boolean;
    website?: string;
    alias?: string;
    titleColor?: string;
  };
}

export interface EditPartnerRes {
  updatePartner: {
    partner: Partner;
  };
}

export const EditPartnerQuery = gql`
  mutation updatePartner($id: ID!, $fields: UpdatePartnerFieldsInput) {
    updatePartner(input: { id: $id, fields: $fields }) {
      partner {
        ...PartnerFragment
      }
    }
  }
  ${PartnerFragment}
`;
