import { gql } from '@apollo/react-hooks';
import { Connection } from '../types/parse';
import { Technology } from '../types/technologies';
import { TechnologyFragment } from './fragments/technology';

export interface GetTechnologiesRes {
  technologies: Connection<Technology>;
}
export interface GetTechnologiesParams {
  order?: Array<string>;
}

export const GetTechnologiesQuery = gql`
  query technologies($order: [TechnologyOrder!]) {
    technologies(order: $order) {
      edges {
        node {
          ...TechnologyFragment
        }
      }
    }
  }
  ${TechnologyFragment}
`;

export interface GetTechnologyParams {
  id: string;
}

export interface GetTechnologyRes {
  technology: Technology;
}

export const GetTechnologyQuery = gql`
  query technology($id: ID!) {
    technology(id: $id) {
      ...TechnologyFragment
    }
  }
  ${TechnologyFragment}
`;

export interface AddTechnologyParams {
  fields: {
    name?: string;
    logo?: {
      link: string;
    };
    showOnMainPage?: boolean;
    url?: string;
  };
}

export interface AddTechnologyRes {
  createTechnology: {
    technology: Technology;
  };
}

export const AddTechnologyQuery = gql`
  mutation addTechnology($fields: CreateTechnologyFieldsInput) {
    createTechnology(input: { fields: $fields }) {
      technology {
        ...TechnologyFragment
      }
    }
  }
  ${TechnologyFragment}
`;

export interface EditTechnologyParams {
  id: string;
  fields: {
    name?: string;
    logo?: {
      link: string | null;
    };
    showOnMainPage?: boolean;
    url?: string;
  };
}

export interface EditTechnologyRes {
  updateTechnology: {
    technology: Technology;
  };
}

export const EditTechnologyQuery = gql`
  mutation editTechnology($id: ID!, $fields: UpdateTechnologyFieldsInput) {
    updateTechnology(input: { id: $id, fields: $fields }) {
      technology {
        ...TechnologyFragment
      }
    }
  }
  ${TechnologyFragment}
`;

export interface DeleteTechnologyParams {
  id: string;
}

export interface DeleteTechnologyRes {
  deleteTechnology: {
    technology: Technology;
  };
}

export const DeleteTechnologyQuery = gql`
  mutation deleteTechnology($id: ID!) {
    deleteTechnology(input: { id: $id }) {
      technology {
        ...TechnologyFragment
      }
    }
  }
  ${TechnologyFragment}
`;
