import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color';
import styled from 'styled-components';

const ColorBlock = styled.div<{ $disabled?: boolean }>`
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #666666;
  box-sizing: border-box;
  opacity: ${({ $disabled }) => ($disabled ? '0.25' : '1')};
`;

const Color = styled.div<{ color?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color || '#000000'};
`;

const Buttons = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;
  & > button {
    margin-right: 10px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

type ColorPickerPropsT = {
  color?: string;
  onChange: (color: string) => void;
  disabled?: boolean;
};

export const ColorPicker: React.FC<ColorPickerPropsT> = ({ color, onChange, disabled }) => {
  const [isOpened, setOpened] = useState(false);
  const [value, setValue] = useState('');
  const handleOpen = () => {
    if (disabled) return;
    setOpened(true);
  };
  const handleChange: ColorChangeHandler = (color: ColorResult) => setValue(color.hex);
  const handleClose = () => {
    setOpened(false);
    setValue('');
  };
  const handleApply = () => {
    onChange(value);
    setOpened(false);
  };
  const handleReset = () => {
    onChange('');
    setOpened(false);
  };

  useEffect(() => {
    if (color) setValue(color);
  }, [color]);

  if (!isOpened)
    return (
      <ColorBlock $disabled={disabled}>
        <Color color={color} onClick={handleOpen} />
      </ColorBlock>
    );

  return (
    <>
      <SketchPicker color={value} onChangeComplete={handleChange} />
      <Buttons>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleReset}>Reset</Button>
        <Button onClick={handleApply} type="primary">
          Apply
        </Button>
      </Buttons>
    </>
  );
};
