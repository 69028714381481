import { message, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

export const uploadValidation = (file: UploadFile) => {
  const uploadFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg', 'image/svg+xml', 'image/webp'];
  const validFile = uploadFileTypes.find((el) => el === file.type);
  if (!validFile) {
    message.error(`${file.name} is not a jpeg/jpg/png/svg/webp file`);
  }

  return validFile || Upload.LIST_IGNORE;
};
