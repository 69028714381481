import { ListObject } from './common';

export interface Section extends ListObject {
  data?: string;
  type?: string;
  layoutType?: string;
  Partner?: { objectId: string };
  Project?: { objectId: string };
}

export enum SectionType {
  PARTNER = 'PARTNER',
  PROJECT = 'PROJECT',
}

export enum SectionTitle {
  html = 'Editor',
  events = 'Events',
  technologies = 'Technologies',
  teams = 'Team',
  projects = 'Projects',
  partner = 'Partner',
}
