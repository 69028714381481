import React from 'react';
import { FormModes, ItemType } from '../../constants/common';
import { ProjectForm } from '../../components/Projects';
import { useEditProject, useGetProject } from '../../hooks/projects';
import { useGetPartnersList } from '../../hooks/partners';
import { useGetTeamsList } from '../../hooks/teams';
import { useGetTechnologiesList } from '../../hooks/technologies';
import { useAddSection, useGetSections, useEditSection, useDeleteSection } from '../../hooks/sections';
import { Section, SectionType } from '../../types/sections';
import { useRearrangeList } from '../../hooks/list';

export const EditProject = () => {
  const { data, loading, refetch } = useGetProject();
  const { editProject } = useEditProject({ onsuccessCb: () => refetch() });
  const { data: partners, loading: partnersLoading } = useGetPartnersList();
  const { data: team, loading: teamLoading } = useGetTeamsList();
  const { data: technologies, loading: technologiesLoading } = useGetTechnologiesList();
  const {
    data: sections,
    refetch: sRefetch,
    loading: sectionsLoading,
  } = useGetSections(SectionType.PROJECT, data?.objectId);
  const { addSection } = useAddSection({ cb: () => sRefetch() }, SectionType.PROJECT, data?.objectId);
  const { editSection } = useEditSection({ onsuccessCb: () => sRefetch() });
  const { deleteSection } = useDeleteSection({ onsuccessCb: () => sRefetch() });
  const rearrangeData = useRearrangeList<Section>({
    items: sections,
    itemType: ItemType.Section,
    onsaveCb: sRefetch,
  });

  const isLoading = loading || partnersLoading || teamLoading || technologiesLoading || sectionsLoading;

  return (
    <ProjectForm
      editProject={editProject}
      loading={isLoading}
      item={data}
      mode={FormModes.Edit}
      partners={partners}
      team={team}
      technologies={technologies}
      addSection={addSection}
      editSection={editSection}
      deleteSection={deleteSection}
      rearrangeData={rearrangeData}
    />
  );
};
