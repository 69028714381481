import React from 'react';
import { FormModes } from '../../constants/common';
import { TeammateForm } from '../../components/Team';
import { useEditTeam, useGetTeam } from '../../hooks/teams';

export const EditTeammate = () => {
  const { data, loading, refetch } = useGetTeam();
  const { editTeam } = useEditTeam({ onsuccessCb: () => refetch() });

  return <TeammateForm editTeam={editTeam} loading={loading} item={data} mode={FormModes.Edit} />;
};
