import { gql } from '@apollo/react-hooks';
import {
  GQLCreateSectionInput,
  GQLPartnerPointerInput,
  GQLProjectPointerInput,
  GQLSectionWhereInput,
} from '../graphql.schema';
import { Connection } from '../types/parse';
import { Section } from '../types/sections';
import { SectionFragment } from './fragments/section';

export interface GetSectionsRes {
  sections: Connection<Section>;
}
export interface GetSectionsParams {
  order?: Array<string>;
  where?: GQLSectionWhereInput;
}

export const GetSectionsQuery = gql`
  query sections($order: [SectionOrder!], $where: SectionWhereInput) {
    sections(order: $order, where: $where) {
      edges {
        node {
          ...SectionFragment
        }
      }
    }
  }
  ${SectionFragment}
`;

export interface GetSectionParams {
  id: string;
}

export interface GetSectionRes {
  section: Section;
}

export const GetSectionQuery = gql`
  query section($id: ID!) {
    section(id: $id) {
      ...SectionFragment
    }
  }
  ${SectionFragment}
`;

export interface AddSectionParams extends GQLCreateSectionInput {}

export interface AddSectionRes {
  createSection: {
    section: Section;
  };
}

export const AddSectionQuery = gql`
  mutation createSection($fields: CreateSectionFieldsInput) {
    createSection(input: { fields: $fields }) {
      section {
        ...SectionFragment
      }
    }
  }
  ${SectionFragment}
`;

export interface DeleteSectionParams {
  id: string;
}

export interface DeleteSectionRes {
  deleteSection: {
    section: Section;
  };
}

export const DeleteSectionQuery = gql`
  mutation deleteSection($id: ID!) {
    deleteSection(input: { id: $id }) {
      section {
        ...SectionFragment
      }
    }
  }
  ${SectionFragment}
`;

export interface EditSectionParams {
  id?: string;
  data?: string;
  type?: string;
  Partner?: GQLPartnerPointerInput;
  Project?: GQLProjectPointerInput;
}

export interface EditSectionRes {
  updateSection: {
    section: Section;
  };
}

export const EditSectionQuery = gql`
  mutation updateSection($id: ID!, $fields: UpdateSectionFieldsInput) {
    updateSection(input: { id: $id, fields: $fields }) {
      section {
        ...SectionFragment
      }
    }
  }
  ${SectionFragment}
`;
