export enum FormModes {
  View = 'View',
  Edit = 'Edit',
  Add = 'Add',
}

export enum ListModes {
  View = 'View',
  EditOrder = 'EditOrder',
}

export enum ListActions {
  MoveUp = 'MoveUp',
  MoveDown = 'MoveDown',
  SetData = 'SetData',
}

export enum ItemType {
  Team = 'Team',
  Event = 'Event',
  Partner = 'Partner',
  Project = 'Project',
  Technology = 'Technology',
  Section = 'Section',
}

export const order = {
  layOut: 'layOut_ASC',
};

export const PROJECT_TYPES = ['web', 'mobile', 'blockchain'];
