import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ListContainer } from '../common/ListContainer';
import { AddButton } from '../common/ActionsButtons';
import { routes } from '../../constants/routes';
import { IEvent } from '../../types/events';
import { RearrangeData } from '../../hooks/list';
import { DeleteEventParams } from '../../queries/events';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { ItemType } from '../../types/common';
import { DragSortingTable } from '../Table';

interface IProps {
  isLoading?: boolean;
  rearrangeData: RearrangeData<IEvent>;
  deleteEvent: (params: DeleteEventParams) => Promise<void>;
  setValue: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<Date | string | undefined>>;
}

export const Events: React.FC<IProps> = ({ isLoading, rearrangeData, deleteEvent, setValue, setDate }) => {
  const { items, moveUp, moveDown, saveOrder } = rearrangeData;
  const [isSave, setIsSave] = useState(0);

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('event'), () => deleteEvent({ id }));
  };
  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  useEffect(() => {
    if (!isSave || !items.length) return;
    saveOrder();
  }, [isSave]);

  const extras = [<AddButton key={Math.random()} to={routes.addEvent} />];

  const options = items?.map((item) => ({ value: item.name }));
  const dateOptions = items?.map((item) => ({ value: item.date }));

  return (
    <ListContainer
      title="Events"
      extra={extras}
      search
      date
      options={options}
      setValue={setValue}
      setDate={setDate}
      dateOptions={dateOptions}
    >
      <DragSortingTable
        view={routes.viewEvent}
        edit={routes.editEvent}
        onMoveUp={handleMoveUp}
        onMoveDown={handleMoveDown}
        items={items as ItemType[]}
        onDelete={handleDelete}
        loading={!!isLoading}
        saveOrder={setIsSave}
      />
    </ListContainer>
  );
};
