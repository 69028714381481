import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TechnologyForm } from '../../components/Technologies/TechnologyForm';
import { FormModes } from '../../constants/common';
import { useAddTechnology } from '../../hooks/technologies';
import { routes } from '../../constants/routes';

export const AddTechnology = () => {
  const navigate = useNavigate();
  const { addTechnology } = useAddTechnology({
    cb: (id) => {
      navigate(`${routes.viewTechnology}/${id}`);
    },
  });

  return <TechnologyForm mode={FormModes.Add} addTechnology={addTechnology} />;
};
