import { gql } from '@apollo/react-hooks';

export const AppFileFragment = gql`
  fragment AppFileFragment on AppFile {
    id
    objectId
    createdAt
    updatedAt
    file {
      url
      name
    }
  }
`;
