import styled from 'styled-components';

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  .rdw-block-dropdown {
    width: 130px;
  }
`;

export const FroalaWrapper = styled.div<{ $disabled?: boolean; titleColor?: string }>`
  width: 100%;
  position: relative;
  .fr-box.fr-basic .fr-element {
    background: #2b2b39 !important;
  }
  h1 {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-size: 52px;
    font-weight: 300;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h2 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 40px;
    font-weight: 400;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h3 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 32px;
    font-weight: 600;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h4 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 24px;
    font-weight: 500;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h5 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 22px;
    font-weight: 600;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h6 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  a,
  ul,
  ol,
  pre {
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    margin: 1em 0;
    line-height: 1.2;
  }
  td,
  p {
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    font-weight: 300;
    margin: 1em 0;
    line-height: 1.2;
  }
  li {
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    font-weight: 300;
    margin: 0.5em 0;
    line-height: 1.2;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ul,
  ol,
  pre,
  td {
    color: #ffffff;
  }
  .fr-dropdown-list * {
    color: #000000;
  }
  .fr-dropdown-list a {
    margin: 5px 0;
  }
  &:after {
    display: ${({ $disabled }) => ($disabled ? 'flex' : 'none')};
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: #000;
    z-index: 100;
    opacity: 0.1;
    border-radius: 10px;
  }
  h1,
  h2,
  h3,
  h4 {
    span {
      ${({ titleColor }) => titleColor && ` color: ${titleColor}!important;`}
    }
  }
`;
