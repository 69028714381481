import React from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import { OptionalLink } from '../OptionalLink';
import { ActionButton } from './types';

const Button = styled(AntButton)`
  text-transform: capitalize;
`;

export const BaseButton: React.FC<ActionButton> = ({ children, to, ...props }) => {
  return (
    <OptionalLink to={to}>
      <Button type="primary" {...props}>
        {children}
      </Button>
    </OptionalLink>
  );
};
