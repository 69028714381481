import React from 'react';
import styled from 'styled-components';
import { PageHeaderProps } from 'antd/lib/page-header';
import { PageHeader, Spin } from 'antd';

const Container = styled.div`
  display: flex;
  justify-content: center;

  .ant-input[disabled] {
    cursor: auto;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  .ant-page-header-heading-extra {
    display: flex;
    flex-direction: row;
  }
`;
interface IProps extends PageHeaderProps {
  loading?: boolean;
}

export const ViewContainer: React.FC<IProps> = ({ children, loading, ...props }) => {
  return (
    <Container>
      <Wrapper>
        <PageHeader {...props}>
          <Spin tip="Loading..." spinning={!!loading}>
            {loading ? null : children}
          </Spin>
        </PageHeader>
      </Wrapper>
    </Container>
  );
};
