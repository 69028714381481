import styled from 'styled-components';
import { Form as AntForm } from 'antd';

export const PageWrapper = styled.div`
  display: flex;
  height: 80vh;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  width: 400px;
  height: max-content;
  max-width: 100vw;
  display: flex;
  align-items: center;
  padding: 48px;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  margin-top: 150px;
  .ant-form {
    padding: 0;
    .ant-form-item {
      margin-bottom: 24px;
    }
  }
`;

export const FormItem = styled(AntForm.Item)`
  margin: 0;
`;

export const Form = styled(AntForm)`
  width: 100%;
  & > div > div {
    display: flex;
    flex-direction: column;
    h1 {
      margin: 0 0 24px;
    }
    h2 {
      margin: 0 0 24px;
    }
    .ant-form-item:last-child {
      margin: 0;
    }
    .ant-btn-link {
      padding: 0;
    }
    .ant-input-prefix {
      margin-right: 8px;
    }
  }
`;
