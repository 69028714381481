import React from 'react';
import { Button, Input, Spin, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Form, FormItem, FormWrapper, PageWrapper } from './styles';
import { AlignedBlock } from '../common/AlignedBlock';
import { AuthData } from '../../types/auth';

interface IProps {
  login: (data: AuthData) => void;
  loading: boolean;
}

export const Auth: React.FC<IProps> = ({ login, loading }) => {
  const onSubmit = (data: unknown) => {
    login(data as AuthData);
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Form size="middle" onFinish={onSubmit} layout="vertical">
          <Spin spinning={loading}>
            <Typography.Title>Moonion</Typography.Title>
            <FormItem
              name="username"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input placeholder="Username" prefix={<MailOutlined />} />
            </FormItem>
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length > 5) {
                      return Promise.resolve();
                    }
                    if (value.length <= 5) {
                      return Promise.reject(new Error('Password must be at least six characters long.'));
                    }
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Password" prefix={<LockOutlined />} />
            </FormItem>
            <FormItem>
              <AlignedBlock variant="space-between">
                <Button type="primary" htmlType="submit" onClick={onSubmit} loading={loading}>
                  Log in
                </Button>
                <Link to="/recovery">
                  <Button type="link" htmlType="button">
                    Restore password
                  </Button>
                </Link>
              </AlignedBlock>
            </FormItem>
          </Spin>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};
