import { gql } from '@apollo/react-hooks';
import { AppFileFragment } from './appfile';
import { TeamFragment } from './team';
import { TechnologyFragment } from './technology';

export const ProjectFragment = gql`
  fragment ProjectFragment on Project {
    id
    objectId
    createdAt
    updatedAt
    name
    showOnMainPage
    website
    alias
    showOnPartner
    type {
      ... on Element {
        value
      }
    }
    Technologies {
      ...TechnologyFragment
    }
    Team {
      ...TeamFragment
    }
    logo {
      ...AppFileFragment
    }
    picture {
      ...AppFileFragment
    }
    Partner {
      objectId
    }
    titleColor
  }

  ${AppFileFragment}
  ${TechnologyFragment}
  ${TeamFragment}
`;
