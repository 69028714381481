import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Space, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { AddSectionFnParams } from '../../hooks/sections';
import { DeleteSectionParams } from '../../queries/sections';
import { Section } from '../../types/sections';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { SectionsSortingTable } from '../Table/SectionsSortingTable';
import { RearrangeData } from '../../hooks/list';
import { ItemType } from '../../types/common';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    align?: string;
  }
}

const spaceStyles = { display: 'flex', margin: '20px 0' };

type ProjectSectionsProps = {
  addSection?: (params: AddSectionFnParams) => Promise<void>;
  editSection?: (id: string, data: string) => Promise<void>;
  deleteSection?: (params: DeleteSectionParams) => Promise<void>;
  disabled?: boolean;
  rearrangeData?: RearrangeData<Section>;
  loading?: boolean;
  partner?: boolean;
  titleColor?: string;
};

export const ProjectSections: React.FC<ProjectSectionsProps> = ({
  addSection,
  editSection,
  deleteSection,
  disabled,
  rearrangeData,
  loading,
  partner,
  titleColor,
}) => {
  const { items: sectionItems, moveUp, moveDown, saveOrder } = rearrangeData!;
  const [isSave, setIsSave] = useState(0);

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('section'), () => deleteSection?.({ id }));
  };
  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  const onAddSection = (values: AddSectionFnParams) => {
    addSection?.(values);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onAddSection({ type: e.key });
  };

  const items: MenuProps['items'] = [
    {
      label: 'Editor',
      key: 'html',
    },
    {
      label: 'Events',
      key: 'events',
      disabled: !!sectionItems.find((s) => s.type === 'events'),
    },
    {
      label: 'Technologies',
      key: 'technologies',
      disabled: !!sectionItems.find((s) => s.type === 'technologies'),
    },
    {
      label: 'Team',
      key: 'teams',
      disabled: !!sectionItems.find((s) => s.type === 'teams'),
    },
    {
      label: 'Partner',
      key: 'partner',
      disabled: !!sectionItems.find((s) => s.type === 'partner') || !partner,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    if (!isSave || !sectionItems.length) return;
    saveOrder();
  }, [isSave]);

  return (
    <>
      <Dropdown menu={menuProps} placement="bottom" disabled={disabled}>
        <Button>
          <Space>
            Add sections
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Space direction="vertical" size="middle" style={spaceStyles}>
        <SectionsSortingTable
          onMoveUp={handleMoveUp}
          onMoveDown={handleMoveDown}
          items={sectionItems as ItemType[]}
          onDelete={handleDelete}
          editSection={editSection}
          loading={!!loading}
          saveOrder={setIsSave}
          disabled={disabled}
          titleColor={titleColor}
        />
      </Space>
    </>
  );
};
