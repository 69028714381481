import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ListContainer } from '../common/ListContainer';
import { AddButton } from '../common/ActionsButtons';
import { routes } from '../../constants/routes';
import { Project } from '../../types/projects';
import { RearrangeData } from '../../hooks/list';
import { DeleteProjectParams } from '../../queries/projects';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { ItemType } from '../../types/common';
import { DragSortingTable } from '../Table';

interface IProps {
  items?: Array<Project>;
  isLoading?: boolean;
  rearrangeData: RearrangeData<Project>;
  deleteProject?: (params: DeleteProjectParams) => Promise<void>;
  setValue: Dispatch<SetStateAction<string>>;
}

export const Projects: React.FC<IProps> = ({ isLoading, rearrangeData, deleteProject, setValue }) => {
  const { items, moveUp, moveDown, saveOrder } = rearrangeData;
  const [isSave, setIsSave] = useState(0);

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('project'), () => deleteProject?.({ id }));
  };
  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  useEffect(() => {
    if (!isSave || !items.length) return;
    saveOrder();
  }, [isSave]);

  const extras = [<AddButton key={Math.random()} to={routes.addProject} />];

  const options = items?.map((item) => ({ value: item.name }));

  return (
    <ListContainer title="Projects" extra={extras} search options={options} setValue={setValue}>
      <DragSortingTable
        view={routes.viewProject}
        edit={routes.editProject}
        onMoveUp={handleMoveUp}
        onMoveDown={handleMoveDown}
        items={items as ItemType[]}
        onDelete={handleDelete}
        loading={!!isLoading}
        saveOrder={setIsSave}
      />
    </ListContainer>
  );
};
