export const routes = {
  any: '*',
  main: '/',

  technologies: '/technologies',
  viewTechnology: '/view-technology',
  editTechnology: '/edit-technology',
  addTechnology: '/add-technology',

  team: '/team',
  viewTeammate: '/view-teammate',
  editTeammate: '/edit-teammate',
  addTeammate: '/add-teammate',

  events: '/events',
  viewEvent: '/view-event',
  editEvent: '/edit-event',
  addEvent: '/add-event',

  projects: '/projects',
  viewProject: '/view-project',
  editProject: '/edit-project',
  addProject: '/add-project',

  partners: '/partners',
  viewPartner: '/view-partner',
  editPartner: '/edit-partner',
  addPartner: '/add-partner',

  recovery: '/recovery',
  change: '/change-password',
};
