import { gql } from '@apollo/react-hooks';
import { AppFileFragment } from './appfile';

export const PartnerFragment = gql`
  fragment PartnerFragment on Partner {
    logo {
      ...AppFileFragment
    }
    descr
    name
    objectId
    showOnMainPage
    alias
    website
    titleColor
  }
  ${AppFileFragment}
`;
