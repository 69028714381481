import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { PlusOutlined } from '@ant-design/icons';
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color';
import { ViewContainer } from '../common/ViewContainer';
import { routes } from '../../constants/routes';
import { FormModes, PROJECT_TYPES } from '../../constants/common';
import { AlignedBlock } from '../common/AlignedBlock';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { Br } from '../common/Br';
import { Project } from '../../types/projects';
import { Upload } from '../common/Upload';
import { AddProjectType, EditProjectType } from '../../hooks/projects';
import { DeleteProjectParams } from '../../queries/projects';
import { FileStatus } from '../../constants/file';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { Img } from '../common/Img';
import { uploadValidation } from '../../helpers/validators';
import { dummyRequest } from '../../helpers/common';
import { Partner } from '../../types/partners';
import { Teammate } from '../../types/team';
import { Technology } from '../../types/technologies';
import { AddSectionFnParams } from '../../hooks/sections';
import { Section } from '../../types/sections';
import { DeleteSectionParams } from '../../queries/sections';
import { ProjectSections } from './ProjectSections';
import { RearrangeData } from '../../hooks/list';
import { BlockerComponent } from '../common/Blocker';
import { compareObject, parseProject } from '../../helpers/form';
import { ColorPicker } from '../common/ColorPicker';

const titles: Record<FormModes, string> = {
  [FormModes.View]: 'Project',
  [FormModes.Edit]: 'Edit Project',
  [FormModes.Add]: 'Add Project',
};

interface IProps {
  mode: FormModes;
  item?: Project;
  loading?: boolean;
  createProject?: (params: AddProjectType) => Promise<void>;
  deleteProject?: (params: DeleteProjectParams) => Promise<void>;
  editProject?: (params: EditProjectType) => Promise<void>;
  partners?: Partner[];
  team?: Teammate[];
  technologies?: Technology[];
  addSection?: (params: AddSectionFnParams) => Promise<void>;
  editSection?: (id: string, data: string) => Promise<void>;
  deleteSection?: (params: DeleteSectionParams) => Promise<void>;
  rearrangeData?: RearrangeData<Section>;
}

export const ProjectForm: React.FC<IProps> = ({
  mode,
  item,
  createProject,
  editProject,
  loading,
  deleteProject,
  partners,
  team,
  technologies,
  addSection,
  editSection,
  deleteSection,
  rearrangeData,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState<UploadFile | undefined>(
    item?.logo?.file.url ? { uid: '1', name: '', url: item.logo.file.url } : undefined
  );
  const [picture, setPicture] = useState<UploadFile | undefined>(
    item?.picture?.file.url ? { uid: '1', name: '', url: item.picture.file.url } : undefined
  );
  const [isBlocked, setBlocked] = useState(false);
  const [titleColor, setColor] = useState('');
  const parseData = useMemo(() => parseProject(item), [item]);

  const onBack = () => {
    navigate(routes.projects);
  };

  const goToViewMode = () => {
    navigate(`${routes.viewProject}/${item?.objectId}`);
  };
  const goToEditMode = () => {
    navigate(`${routes.editProject}/${item?.objectId}`);
  };
  const goToList = () => {
    navigate(routes.projects);
  };

  const onCreate = (values: AddProjectType) => {
    createProject?.(values).then(goToList);
  };
  const onSave = (values: EditProjectType) => {
    const id = item?.objectId;
    if (!id) return;

    editProject?.({ id, ...values })
      .then(() => setBlocked(false))
      .then(goToList);
  };

  const onFinish = (values: any) => {
    const logoFileIsDeleted = values.logo?.file?.status === FileStatus.removed;
    const logoFile = logoFileIsDeleted ? null : values.logo?.file?.originFileObj;
    delete logoFile?.uid;

    const pictureFileIsDeleted = values.picture?.file?.status === FileStatus.removed;
    const pictureFile = pictureFileIsDeleted ? null : values.picture?.file?.originFileObj;
    delete pictureFile?.uid;

    if (mode === FormModes.Add) return onCreate({ ...values, titleColor, logo: logoFile, picture: pictureFile });
    if (mode === FormModes.Edit)
      return onSave({
        ...values,
        titleColor,
        logo: { logoFile, id: item?.logo?.id },
        picture: { pictureFile, id: item?.picture?.id },
      });
  };

  const onCancel = () => {
    goToViewMode();
  };

  const onDelete = () => {
    const id = item?.objectId;

    if (id)
      confirm(confirmDelete('project'), async () => {
        await deleteProject?.({ id });
        onBack();
      });
  };
  const setLogoFile = (info: UploadChangeParam<UploadFile>) => {
    return info.fileList.length ? setLogo({ ...info.file, status: 'done' }) : setLogo(undefined);
  };
  const setPictureFile = (info: UploadChangeParam<UploadFile>) => {
    return info.fileList.length ? setPicture({ ...info.file, status: 'done' }) : setPicture(undefined);
  };
  const setTitleColor = (color: string) => setColor(color);
  const extra: Array<ReactNode> =
    mode === FormModes.View
      ? [
          <EditButton key={Math.random()} onClick={goToEditMode} />,
          <DeleteButton key={Math.random()} onClick={onDelete} />,
        ]
      : [];

  const disabled = mode === FormModes.View;
  const onCompare = () => {
    const value = !compareObject(form.getFieldsValue(), parseData);
    setBlocked((prev) => (prev === value ? prev : value));
  };
  useEffect(() => {
    const logoUrl = item?.logo?.file.url;
    const pictureUrl = item?.picture?.file.url;
    if (logoUrl && pictureUrl) {
      setPicture({ uid: pictureUrl, url: pictureUrl, name: '' });
      setLogo({ uid: logoUrl, url: logoUrl, name: '' });
    }
    if (item?.titleColor) setColor(item.titleColor);
  }, [item?.picture?.file.url, item?.logo?.file.url]);

  return (
    <ViewContainer
      loading={loading}
      onBack={mode === FormModes.Edit ? onCancel : onBack}
      extra={extra}
      title={titles[mode]}
    >
      {mode === FormModes.Edit && <BlockerComponent isBlocked={isBlocked} form={form} object={parseData} />}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onCompare}
        initialValues={{
          name: item?.name,
          showOnMainPage: item?.showOnMainPage,
          showOnPartner: item?.showOnPartner,
          Partner: item?.Partner?.objectId,
          Technologies: item?.Technologies?.map((el) => el.objectId),
          Team: item?.Team?.map((el) => el.objectId),
          website: item?.website,
          alias: item?.alias,
          type: item?.type?.map((el) => el?.value),
        }}
      >
        <Form.Item
          label="Logo"
          name="logo"
          rules={
            !logo
              ? [
                  {
                    required: true,
                    message: 'Please input logo!',
                  },
                ]
              : undefined
          }
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={logo ? [logo] : []}
            showUploadList
            onChange={setLogoFile}
            disabled={mode === FormModes.View}
            name="logo"
            customRequest={dummyRequest}
            beforeUpload={uploadValidation}
          >
            {!logo && mode === FormModes.View ? (
              <Img />
            ) : (
              !logo && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Picture"
          name="picture"
          rules={
            !picture
              ? [
                  {
                    required: true,
                    message: 'Please input picture!',
                  },
                ]
              : undefined
          }
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={picture ? [picture] : []}
            showUploadList
            onChange={setPictureFile}
            disabled={mode === FormModes.View}
            name="picture"
            customRequest={dummyRequest}
            beforeUpload={uploadValidation}
          >
            {!picture && mode === FormModes.View ? (
              <Img />
            ) : (
              !picture && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('Name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={disabled} name="name" />
        </Form.Item>
        <Form.Item valuePropName="checked" name="showOnMainPage">
          <Checkbox disabled={disabled} name="showOnMainPage">
            Show on Main Page
          </Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked" name="showOnPartner">
          <Checkbox disabled={disabled} name="showOnPartner">
            Show on Partner Page
          </Checkbox>
        </Form.Item>
        <Form.Item label="Project type" name="type">
          <Select
            showSearch
            allowClear
            placeholder="Select a type"
            optionFilterProp="children"
            disabled={disabled}
            mode="multiple"
          >
            {PROJECT_TYPES?.map((el) => (
              <Select.Option value={el}>{el}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {(mode === FormModes.Edit || mode === FormModes.View) && (
          <Form.Item
            label="Alias"
            name="alias"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (!getFieldValue('alias').match('^[a-zA-Z0-9_.-]*$')) {
                    return Promise.reject(new Error('Please fill correct alias (letters, numbers, dashes)'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input disabled={disabled} name="alias" />
          </Form.Item>
        )}
        <Form.Item label="Website" name="website">
          <Input disabled={disabled} name="website" />
        </Form.Item>
        <Form.Item label="Related partner" name="Partner">
          <Select showSearch allowClear placeholder="Select a partner" optionFilterProp="children" disabled={disabled}>
            {partners?.map((el) => (
              <Select.Option value={el.objectId}>{el.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Technologies used" name="Technologies">
          <Select
            showSearch
            mode="multiple"
            placeholder="Choose technologies"
            optionFilterProp="children"
            disabled={disabled}
          >
            {technologies?.map((el) => (
              <Select.Option value={el.objectId}>{el.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Team members" name="Team">
          <Select
            showSearch
            mode="multiple"
            placeholder="Choose teammates"
            optionFilterProp="children"
            disabled={disabled}
          >
            {team?.map((el) => (
              <Select.Option value={el.objectId}>{`${el.firstName}${el.lastName}`}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Title Color">
          <ColorPicker color={titleColor} onChange={setTitleColor} disabled={disabled} />
        </Form.Item>
        {(mode === FormModes.Edit || mode === FormModes.View) && (
          <ProjectSections
            partner={!!item?.Partner?.objectId}
            addSection={addSection}
            editSection={editSection}
            deleteSection={deleteSection}
            disabled={mode === FormModes.View}
            rearrangeData={rearrangeData}
            loading={loading}
            titleColor={titleColor}
          />
        )}

        {mode === FormModes.Edit && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button onClick={onCancel}>Cancel</Button>
              <Br hrIndent={8} />
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
        {mode === FormModes.Add && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
      </Form>
    </ViewContainer>
  );
};
