import React, { useEffect } from 'react';
import { unstable_useBlocker as useBlocker, Outlet } from 'react-router-dom';
import { FormInstance } from 'antd/es/form';
import { confirm } from '../Modals';
import { confirmChangePage } from '../../../constants/modals';
import { compareObject } from '../../../helpers/form';
import { AppFile } from '../../../types/common';

interface BlockerProps {
  isBlocked: boolean;
  form: FormInstance<any>;
  object: {
    qr?: string;
    name?: string;
    email?: string;
    phone?: string;
    alias?: string;
    website?: string;
    position?: string;
    lastName?: string;
    firstName?: string;
    logo?: AppFile;
    avatar?: AppFile;
    picture?: AppFile;
    birthDate?: Date;
    Partner?: string;
    Team?: (string | undefined)[];
    Technologies?: (string | undefined)[];
    showInTeam?: boolean;
    showOnMainPage?: boolean;
    showInContacts?: boolean;
    showInPartner?: boolean;
  };
}

export const BlockerComponent: React.FC<BlockerProps> = ({ isBlocked }) => {
  const blocker = useBlocker(isBlocked);
  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (!isBlocked) return blocker.reset();
      confirm(confirmChangePage(), blocker.proceed);
    }
  }, [blocker, isBlocked]);

  return (
    <>
      <Outlet />
    </>
  );
};
