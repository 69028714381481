import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { message } from 'antd';
import {
  AddEventParams,
  AddEventQuery,
  AddEventRes,
  DeleteEventParams,
  DeleteEventQuery,
  DeleteEventRes,
  EditEventParams,
  EditEventQuery,
  EditEventRes,
  GetEventParams,
  GetEventQuery,
  GetEventRes,
  GetEventsParams,
  GetEventsQuery,
  GetEventsRes,
} from '../queries/events';
import { order } from '../constants/common';
import { GQLCreateEventFieldsInput, GQLCreateEventInput, GQLUpdateEventInput } from '../graphql.schema';
import { IEvent } from '../types/events';

export const useGetEventsList = (value?: string, date?: Date | string) => {
  const nameWhere = value ? { name: { equalTo: value } } : {};
  const dateWhere = date ? { date: { equalTo: date } } : {};
  const { data, ...otherData } = useQuery<GetEventsRes, GetEventsParams>(GetEventsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      order: [order.layOut],
      where: {
        ...nameWhere,
        ...dateWhere,
      },
    },
  });

  const _data = useMemo(
    () =>
      data?.events.edges.map((el) => {
        return {
          ...el.node,
          descr: (el?.node?.descr || '')?.replace(/style/gi, 'fr-original-style'),
        } as IEvent;
      }),
    [data]
  );

  return { data: _data, ...otherData };
};

export const useGetEvent = () => {
  const { id } = useParams<{ id: string }>();
  const { data, ...otherData } = useQuery<GetEventRes, GetEventParams>(GetEventQuery, {
    variables: { id: id || '' },
  });

  return {
    data: {
      ...data?.event,
      descr: (data?.event?.descr || '')?.replace(/style/gi, 'fr-original-style'),
    } as IEvent,
    ...otherData,
  };
};

export type AddEventFnParams = GQLCreateEventFieldsInput;

export const useAddEvent = (options?: { cb?: (id: string) => void }) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddEventRes, AddEventParams>(AddEventQuery);

  const addEvent = async (_params: AddEventFnParams) => {
    const { ...params } = _params;

    try {
      const { data } = await add({
        variables: {
          fields: {
            ...params,
            descr: params?.descr?.replace(/ style=".+?"/gi, '')?.replace(/fr-original-style/gi, 'style'),
            ...(params?.Project ? { Project: { link: params.Project } } : {}),
          },
        } as GQLCreateEventInput,
      });
      const id = data?.createEvent.event.objectId;
      if (id) cb?.(id);
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { addEvent, ...otherData };
};

export type EditEventFnParams = Omit<EditEventParams, 'logo'>;

export const useEditEvent = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [edit, otherData] = useMutation<EditEventRes, EditEventParams>(EditEventQuery);

  const editEvent = async (_params: EditEventFnParams) => {
    const { ...params } = _params;

    try {
      await edit({
        variables: {
          id: params?.id,
          fields: {
            descr: params?.descr?.replace(/ style=".+?"/gi, '')?.replace(/fr-original-style/gi, 'style'),
            name: params?.name,
            date: params?.date,
            showOnMainPage: params?.showOnMainPage,
            showOnPartnersPage: params?.showOnPartnersPage,
            ...(params?.Project ? { Project: { link: params.Project } } : {}),
          },
        } as GQLUpdateEventInput,
      });

      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editEvent, ...otherData };
};

export const useDeleteEvent = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeleteEventRes, DeleteEventParams>(DeleteEventQuery);

  const deleteEvent = async (params: DeleteEventParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deleteEvent, otherData };
};
