import { gql } from '@apollo/react-hooks';
import { EventFragment } from './fragments/event';
import { Connection } from '../types/parse';
import { IEvent } from '../types/events';
import { GQLCreateEventInput, GQLProjectPointerInput } from '../graphql.schema';

export interface GetEventsRes {
  events: Connection<IEvent>;
}

export interface GetEventsParams {
  order?: Array<string>;
  where?: {
    name?: {
      equalTo?: string;
    };
  };
}

export const GetEventsQuery = gql`
  query GetEvents($order: [EventOrder!], $where: EventWhereInput) {
    events(order: $order, where: $where) {
      edges {
        node {
          ...EventFragment
        }
      }
    }
  }
  ${EventFragment}
`;

export interface GetEventRes {
  event: IEvent;
}

export interface GetEventParams {
  id: string;
}

export const GetEventQuery = gql`
  query GetEvent($id: ID!) {
    event(id: $id) {
      ...EventFragment
    }
  }
  ${EventFragment}
`;

export interface AddEventRes {
  createEvent: {
    event: IEvent;
  };
}

export interface AddEventParams extends GQLCreateEventInput {}

export const AddEventQuery = gql`
  mutation AddEvent($fields: CreateEventFieldsInput) {
    createEvent(input: { fields: $fields }) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragment}
`;

export interface EditEventRes {
  updateEvent: {
    event: IEvent;
  };
}

export interface EditEventParams {
  id?: string;
  descr?: string;
  name?: string;
  date?: Date;
  showOnMainPage?: boolean;
  showOnPartnersPage?: boolean;
  Project?: GQLProjectPointerInput;
}

export const EditEventQuery = gql`
  mutation EditEvent($id: ID!, $fields: UpdateEventFieldsInput) {
    updateEvent(input: { id: $id, fields: $fields }) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragment}
`;

export interface DeleteEventParams {
  id: string;
}

export interface DeleteEventRes {
  deleteEvent: {
    event: IEvent;
  };
}

export const DeleteEventQuery = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(input: { id: $id }) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragment}
`;
