import React from 'react';
import { BaseButton } from './BaseButton';
import { ActionButton } from './types';

export const EditButton: React.FC<ActionButton> = (props) => {
  return (
    <BaseButton ghost {...props}>
      edit
    </BaseButton>
  );
};
