import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { order } from '../constants/common';
import {
  AddPartnerParams,
  AddPartnerQuery,
  AddPartnerRes,
  DeletePartnerParams,
  DeletePartnerQuery,
  DeletePartnerRes,
  EditPartnerParams,
  EditPartnerQuery,
  EditPartnerRes,
  GetPartnerParams,
  GetPartnerQuery,
  GetPartnerRes,
  GetPartnersListParams,
  GetPartnersListQuery,
  GetPartnersListRes,
} from '../queries/partners';
import { AppFile, LoadFile } from '../types/common';
import {
  CreateAppFileQuery,
  CreateFileParams,
  CreateFileRes,
  UpdateAppFileQuery,
  UpdateFileParams,
  UpdateFileRes,
} from '../queries/appfile';
import { GQLAppFilePointerInput } from '../graphql.schema';
import { changeFileName } from '../helpers/common';
import { Partner } from '../types/partners';

export const useGetPartner = () => {
  const { id } = useParams<{ id: string }>();
  const { data, ...otherData } = useQuery<GetPartnerRes, GetPartnerParams>(GetPartnerQuery, {
    variables: { id: id || '' },
  });

  return {
    data: {
      ...data?.partner,
      descr: data?.partner?.descr || '',
    } as Partner,
    ...otherData,
  };
};
export const useGetPartnersList = () => {
  const { data, ...otherData } = useQuery<GetPartnersListRes, GetPartnersListParams>(GetPartnersListQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      order: [order.layOut],
    },
  });

  const _data = useMemo(
    () =>
      data?.partners.edges.map((el) => {
        return {
          ...el.node,
          descr: (el.node?.descr || '')?.replace(/style/gi, 'fr-original-style'),
        } as Partner;
      }),
    [data]
  );

  return { data: _data, ...otherData };
};

export type AddPartnerType = Partial<Omit<AddPartnerParams['fields'], 'logo'> & { logo: LoadFile }>;

export const useAddPartner = (options?: { cb?: (id: string) => void }) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddPartnerRes, AddPartnerParams>(AddPartnerQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);

  const addPartner = async (_params: AddPartnerType) => {
    const { logo, ...params } = _params;
    try {
      let logoInfo: AppFile | undefined;

      if (logo) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(logo as File),
          } as GQLAppFilePointerInput,
        });
        logoInfo = response.data?.createAppFile.appFile;
      }

      const { data } = await add({
        variables: {
          fields: {
            ...params,
            descr: params?.descr?.replace(/ style=".+?"/gi, '')?.replace(/fr-original-style/gi, 'style'),
            ...(logoInfo?.objectId
              ? {
                  logo: {
                    link: logoInfo.objectId,
                  },
                }
              : {}),
          },
        },
      });
      const id = data?.createPartner.partner.objectId;
      if (id) cb?.(id);
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { addPartner, ...otherData };
};

export const useDeletePartner = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeletePartnerRes, DeletePartnerParams>(DeletePartnerQuery);

  const deletePartner = async (params: DeletePartnerParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deletePartner, otherData };
};

export type EditPartnerType = Omit<EditPartnerParams['fields'], 'logo'> & {
  logo?: { file: LoadFile; id?: string };
  id?: string;
};

export const useEditPartner = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [edit, otherData] = useMutation<EditPartnerRes, EditPartnerParams>(EditPartnerQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);
  const [updateAppFile] = useMutation<UpdateFileRes, UpdateFileParams>(UpdateAppFileQuery);

  const editPartner = async (_params: EditPartnerType) => {
    const { logo, id: partnerId, ...params } = _params;
    const { id, file } = logo || {};

    try {
      let logoInfo: AppFile | undefined;

      if (file && !id) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(file),
          } as GQLAppFilePointerInput,
        });

        logoInfo = response.data?.createAppFile.appFile;
      } else if (file && id) {
        const response = await updateAppFile({
          variables: {
            upload: changeFileName(file),
            id,
          },
        });

        logoInfo = response.data?.UpdateAppFile?.appFile;
      }

      await edit({
        variables: {
          id: partnerId,
          fields: {
            ...params,
            descr: params?.descr?.replace(/ style=".+?"/gi, '')?.replace(/fr-original-style/gi, 'style'),
          },
          ...(logoInfo?.objectId
            ? {
                logo: {
                  link: logoInfo?.objectId,
                },
              }
            : {}),
        },
      });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editPartner, ...otherData };
};
