import React from 'react';
import { Technologies } from '../../components/Technologies';
import { useDeleteTechnology, useGetTechnologiesList } from '../../hooks/technologies';
import { useRearrangeList } from '../../hooks/list';
import { Technology } from '../../types/technologies';
import { ItemType } from '../../constants/common';

export const TechnologiesContainer = () => {
  const { data, refetch, loading } = useGetTechnologiesList();
  const { deleteTechnology } = useDeleteTechnology({
    onsuccessCb: refetch,
  });
  const rearrangeData = useRearrangeList<Technology>({
    items: data,
    itemType: ItemType.Technology,
    onsaveCb: refetch,
  });

  const isLoading = loading || rearrangeData.orderData.loading;

  return <Technologies deleteTechnology={deleteTechnology} isLoading={isLoading} rearrangeData={rearrangeData} />;
};
