export const getStr = (str?: string) => (str ? `${str} ` : '');

export const getFullName = (person: { firstName?: string; lastName?: string; patronymic?: string }) => {
  const { firstName, lastName, patronymic } = person;

  return `${getStr(firstName)}${getStr(lastName)}${getStr(patronymic)}`;
};

export const isMobileDevice = document.documentElement.clientWidth <= 768;

// need to prevent POST 404 error while uploading
export const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const changeFileName = (file: File): File => {
  let myRenamedFile: File | null = null;
  const ext = file.name.split('.').pop();
  const newName = `file_${Date.now()}.${ext}`;
  myRenamedFile = new File([file], newName, { type: file.type });

  return myRenamedFile || file;
};

export const filterPB = (content: string) => {
  const pb =
    '<p data-f-id="pbf">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';
  return content.replaceAll(pb, '');
};
