import React, { useState, useRef, useEffect, MouseEventHandler, Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Avatar, Space, Table, Tag, Typography } from 'antd';
import { PictureOutlined, UserOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { AppFile, ItemType } from '../../types/common';
import { getFullName } from '../../helpers/common';

export const type = 'DraggableBodyRow';

type DataType = {
  key: string;
  name?: string;
  action: boolean;
  objectId: string;
  avatar?: AppFile;
  onDelete: (id: string) => MouseEventHandler<HTMLElement> | undefined;
  view: string;
  edit: string;
  isPerson: boolean;
  date?: Date;
};

export const DraggableBodyRow = ({ ...restProps }) => {
  const { index, className, style, onMoveUp, onMoveDown, record, saveOrder, data, ...props } = restProps;
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      if (item.index < index) {
        const id = data ? data[item.index].objectId : null;
        for (let i = 0; i < index - item.index; i += 1) {
          onMoveDown(id)();
        }
      }
      if (item.index > index) {
        const id = data ? data[item.index].objectId : null;
        for (let i = 0; i < item.index - index; i += 1) {
          onMoveUp(id)();
        }
      }
      saveOrder(Math.random());
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      key={record?.objectId}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={style}
      {...props}
    />
  );
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '100%',
    render: (_: any, item: DataType) => (
      <Link to={`${item.view}/${item.objectId}`}>
        <Space size="middle">
          <Avatar src={item.avatar?.file.url} icon={item?.isPerson ? <UserOutlined /> : <PictureOutlined />} />
          <Typography.Title level={5}>{item.name}</Typography.Title>
        </Space>
      </Link>
    ),
  },
  {
    title: 'Event date',
    dataIndex: 'data',
    key: 'date',
    render: (_: any, item: DataType) => {
      if (item.view.includes('event'))
        return (
          <Space size="middle">
            <Tag color="green">{format(new Date(item.date as Date), 'MMMM yyyy')}</Tag>
          </Space>
        );
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: any, item: DataType) => (
      <Space size="middle">
        <EditButton to={`${item.edit}/${item?.objectId}`} />
        <DeleteButton onClick={item.onDelete(item?.objectId)} />
      </Space>
    ),
  },
];

interface Props {
  items: ItemType[];
  onDelete: (id: string) => MouseEventHandler<HTMLElement> | undefined;
  loading: boolean;
  view: string;
  edit: string;
  onMoveUp: (id: string) => void;
  onMoveDown: (id: string, count: number) => void;
  saveOrder: Dispatch<SetStateAction<number>>;
}

export const DragSortingTable: React.FC<Props> = ({
  items,
  onDelete,
  loading,
  view,
  edit,
  onMoveUp,
  onMoveDown,
  saveOrder,
}) => {
  const [data, setData] = useState<Array<DataType>>([]);

  useEffect(() => {
    setData(
      items.map((item: ItemType) => ({
        key: item.objectId,
        name: item?.firstName ? getFullName(item) : item?.name || item?.title,
        action: true,
        objectId: item?.objectId,
        avatar: item?.avatar || item?.logo,
        onDelete,
        view,
        edit,
        isPerson: !!item?.firstName,
        date: item?.date,
      }))
    );
  }, [items]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        loading={loading}
        showHeader={false}
        pagination={false}
        columns={columns}
        dataSource={data}
        components={components}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRow={(record, index) => ({
          record,
          index,
          onMoveUp,
          onMoveDown,
          data,
          saveOrder,
        })}
      />
    </DndProvider>
  );
};
