import React from 'react';
import { EventForm } from '../../components/Events/EventForm';
import { FormModes } from '../../constants/common';
import { useEditEvent, useGetEvent } from '../../hooks/events';
import { useGetProjectsList } from '../../hooks/projects';

export const EditEvent = () => {
  const { data, loading, refetch } = useGetEvent();
  const { editEvent } = useEditEvent({ onsuccessCb: () => refetch() });
  const { data: projectsData, loading: projectsLoading } = useGetProjectsList('');

  return (
    <EventForm
      loading={loading || projectsLoading}
      mode={FormModes.Edit}
      item={data}
      editEvent={editEvent}
      projects={projectsData}
    />
  );
};
