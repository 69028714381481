import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Spin, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Form, FormItem, FormWrapper, PageWrapper } from './styles';
import { AlignedBlock } from '../common/AlignedBlock';

interface Props {
  loading: boolean;
  onSubmit: (password: string) => void;
}

export const ChangePassword: React.FC<Props> = ({ loading, onSubmit }) => {
  const [error, setError] = useState(false);

  const handleSubmit = (values: { password: string }) => {
    onSubmit(values.password);
  };

  const handleClearError = () => {
    if (error) return setError(false);
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Form size="middle" onFinish={handleSubmit as (values: unknown) => void} layout="vertical">
          <Spin spinning={loading}>
            <Typography.Title level={2}>Change password</Typography.Title>
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length > 5) {
                      return Promise.resolve();
                    }
                    if (value.length <= 5) {
                      return Promise.reject(new Error('Password must be at least six characters long.'));
                    }
                  },
                }),
              ]}
            >
              <Input.Password onChange={handleClearError} placeholder="Password" prefix={<LockOutlined />} />
            </FormItem>
            <FormItem
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || (getFieldValue('password') === value && value.length > 5)) {
                      return Promise.resolve();
                    }
                    if (value.length <= 5) {
                      return Promise.reject(new Error('Password must be at least six characters long.'));
                    }
                    if (getFieldValue('password') !== value) {
                      return Promise.reject(new Error('Password must match.'));
                    }
                  },
                }),
              ]}
            >
              <Input.Password onChange={handleClearError} placeholder="Confirm password" prefix={<LockOutlined />} />
            </FormItem>
            <FormItem>
              <AlignedBlock variant="space-between">
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
                <Link to="/">
                  <Button type="link" htmlType="button">
                    Cancel
                  </Button>
                </Link>
              </AlignedBlock>
            </FormItem>
          </Spin>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};
