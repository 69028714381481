import React, { useEffect, useState } from 'react';
import { Technology } from '../../types/technologies';
import { ListContainer } from '../common/ListContainer';
import { AddButton } from '../common/ActionsButtons';
import { routes } from '../../constants/routes';
import { DeleteTechnologyParams } from '../../queries/technologies';
import { confirm } from '../common/Modals';
import { RearrangeData } from '../../hooks/list';
import { confirmDelete } from '../../constants/modals';
import { ItemType } from '../../types/common';
import { DragSortingTable } from '../Table';

interface IProps {
  isLoading?: boolean;
  deleteTechnology: (params: DeleteTechnologyParams) => void;
  rearrangeData: RearrangeData<Technology>;
}

export const Technologies: React.FC<IProps> = ({ isLoading, deleteTechnology, rearrangeData }) => {
  const { items, moveUp, moveDown, saveOrder } = rearrangeData;
  const [isSave, setIsSave] = useState(0);

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('technology'), () => deleteTechnology({ id }));
  };
  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  useEffect(() => {
    if (!isSave || !items.length) return;
    saveOrder();
  }, [isSave]);

  const extras = [<AddButton key={Math.random()} to={routes.addTechnology} />];

  return (
    <ListContainer title="Technology stack" extra={extras}>
      <DragSortingTable
        view={routes.viewTechnology}
        edit={routes.editTechnology}
        onMoveUp={handleMoveUp}
        onMoveDown={handleMoveDown}
        items={items as ItemType[]}
        onDelete={handleDelete}
        loading={!!isLoading}
        saveOrder={setIsSave}
      />
    </ListContainer>
  );
};
