import React from 'react';
import { FormModes } from '../../constants/common';
import { TeammateForm } from '../../components/Team';
import { useAddTeam, useGetTeamsList } from '../../hooks/teams';

export const AddTeammate = () => {
  const { refetch } = useGetTeamsList();
  const { createTeam } = useAddTeam({ cb: () => refetch() });

  return <TeammateForm createTeam={createTeam} mode={FormModes.Add} />;
};
