import React, { Dispatch, SetStateAction } from 'react';
import { AutoComplete, Input, PageHeader, Select, Spin } from 'antd';
import styled from 'styled-components';
import { PageHeaderProps } from 'antd/lib/page-header';
import { format } from 'date-fns';

const PageWrapper = styled(PageHeader)`
  display: flex;
  justify-content: center;

  & > div:first-child {
    width: 100%;
  }

  .ant-page-header-heading-extra {
    display: flex;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
`;

interface IProps extends PageHeaderProps {
  loading?: boolean;
  search?: boolean;
  date?: boolean;
  options?: {
    value?: string;
  }[];
  dateOptions?: {
    value?: Date | string;
  }[];
  setValue?: Dispatch<SetStateAction<string>>;
  setDate?: Dispatch<SetStateAction<Date | string | undefined>>;
}

export const ListContainer: React.FC<IProps> = ({
  children,
  loading,
  options,
  search,
  setValue,
  dateOptions,
  setDate,
  date,
  ...props
}) => {
  const searchPlaceholder =
    props.title === 'Projects' ? 'Select a project' : props.title === 'Team' ? 'Select a teammate' : 'Select an event';

  return (
    <PageWrapper>
      <div>
        <PageHeader {...props}>
          {search && !!setValue && (
            <SearchContainer>
              <AutoComplete
                onChange={(el) => setValue(el)}
                dropdownClassName="certain-category-search-dropdown"
                options={options || []}
              >
                <Input.Search size="large" placeholder={searchPlaceholder} />
              </AutoComplete>
              {date && (
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a date"
                  optionFilterProp="children"
                  onChange={setDate}
                >
                  {dateOptions?.length &&
                    dateOptions.map((el) =>
                      el.value ? (
                        <Select.Option value={el.value}>{format(new Date(el?.value), 'yyyy/MM')}</Select.Option>
                      ) : null
                    )}
                </Select>
              )}
            </SearchContainer>
          )}
          <Spin tip="Loading..." spinning={!!loading}>
            {children}
          </Spin>
        </PageHeader>
      </div>
    </PageWrapper>
  );
};
