import React, { useState } from 'react';
import { Projects } from '../../components/Projects';
import { useRearrangeList } from '../../hooks/list';
import { ItemType } from '../../constants/common';
import { useDeleteProject, useGetProjectsList } from '../../hooks/projects';
import { Project } from '../../types/projects';

export const ProjectsContainer = () => {
  const [value, setValue] = useState('');
  const { data, loading, refetch } = useGetProjectsList(value);

  const { deleteProject } = useDeleteProject({ onsuccessCb: () => refetch() });

  const rearrangeData = useRearrangeList<Project>({
    items: data,
    itemType: ItemType.Project,
    onsaveCb: refetch,
  });

  const isLoading = loading || rearrangeData.orderData.loading;
  return (
    <Projects deleteProject={deleteProject} rearrangeData={rearrangeData} isLoading={isLoading} setValue={setValue} />
  );
};
