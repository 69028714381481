import { atom, selector } from 'recoil';
import { Session as SessionType } from '../types/auth';
import { User as UserType } from '../types/user';

export type SessionStateType = SessionType | null | undefined;

export const sessionState = atom<SessionStateType>({
  key: 'session',
  default: undefined,
});

export const userState = selector<UserType | null>({
  key: 'user',
  get: ({ get }) => {
    const s = get(sessionState);
    if (!s) {
      return null;
    }
    return s.user;
  },
  set: ({ set, get }, newValue) => {
    const s = get(sessionState);
    if (!s) {
      return null;
    }
    return set(sessionState, { ...s, user: { ...s.user, ...newValue } });
  },
});
