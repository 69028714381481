import React from 'react';
import { Spin } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { useFetchSession } from '../../hooks/auth';
import { AuthContainer } from '../AuthContainer';
import { CentredBlock } from '../../components/common/CentredBlock';
import { Router } from '../../Router';

export const LayoutContainer = () => {
  const { loading, session } = useFetchSession();

  if (loading)
    return (
      <CentredBlock>
        <Spin size="large" />
      </CentredBlock>
    );

  return session ? (
    <Router />
  ) : (
    <BrowserRouter>
      <AuthContainer />
    </BrowserRouter>
  );
};
