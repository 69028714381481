import { Avatar, Layout as AntLayout } from 'antd';
import React from 'react';
import {
  AppstoreOutlined,
  CalendarOutlined,
  GlobalOutlined,
  LogoutOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, Routes, useLocation } from 'react-router-dom';
import { Content, LayoutContainer, Menu, Username, SidebarTitle } from './styles';
import { routes } from '../../constants/routes';
import { Br } from '../common/Br';
import { isMobileDevice } from '../../helpers/common';
import { PublicRoutes } from '../../Router';

const { Sider } = AntLayout;

interface LayoutProps {
  logout: () => void;
  username?: string;
}

export const Layout: React.FC<LayoutProps> = ({ logout, username }) => {
  const path = useLocation().pathname.split('/')[1];

  return (
    <LayoutContainer>
      <Sider collapsible breakpoint="md" collapsedWidth={isMobileDevice ? 0 : 80}>
        <SidebarTitle>
          <Avatar icon={<UserOutlined />} />
          <Username>{username}</Username>
        </SidebarTitle>
        <Menu theme="dark" selectedKeys={[path]} mode="inline">
          <Menu.Item key="technologies" icon={<AppstoreOutlined />}>
            <Link to={routes.technologies}>Technology stack</Link>
          </Menu.Item>
          <Menu.Item key="team" icon={<TeamOutlined />}>
            <Link to={routes.team}>Team</Link>
          </Menu.Item>
          <Menu.Item key="events" icon={<CalendarOutlined />}>
            <Link to={routes.events}>Events</Link>
          </Menu.Item>
          <Menu.Item key="projects" icon={<RocketOutlined />}>
            <Link to={routes.projects}>Projects</Link>
          </Menu.Item>
          <Menu.Item key="partners" icon={<GlobalOutlined />}>
            <Link to={routes.partners}>Partners</Link>
          </Menu.Item>
          <Br vrIndent={40} />
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Content>
        <Routes>{PublicRoutes}</Routes>
      </Content>
    </LayoutContainer>
  );
};
