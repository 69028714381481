import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: #f0f2f5;
  }
  .ant-page-header {
    padding: 0 !important;
  }
  .ant-spin-container {
    background-color: #ffffff;
  }
  .ant-form-item {
    margin: 0 0 16px;
  }
  .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  .ant-form-item-explain-error {
    margin-bottom: 30px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item {
    padding: 0 !important;
  }
  .ant-list-item a {
    flex: 1 1;
    max-width: 100%;
    padding: 12px 0 12px 24px;
  }
  .ant-list-item ul {
    padding: 12px 24px 12px 24px ;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }
  .ant-list-item-action {
    margin: 0;
  }
  .ant-list-item-action a {
    padding: 0;
  }
  .ant-form {
    padding: 20px 40px;
  }
  @media (max-width: 768px) {
    .ant-form {
      padding: 20px;
    }
  }
  .ant-form-item-label > label {
    font-weight: 500;
  }
  .ant-checkbox + span {
    font-weight: 500;
  }
  .ant-list-bordered {
    border: none;
  }
  h5.ant-typography {
    margin: 0 !important;
  }
  .ant-table-cell {
    padding: 0 !important;
    a {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      padding: 12px 24px;
    }
    .ant-space a {
      padding: 0;
    }
    &:last-child {
      padding: 0 24px 0 0 !important;
    }
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  .ant-page-header-content:first-child {
    padding: 0;
  }
  .ant-page-header-content {
    overflow-x: auto;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow: auto;
  }
  textarea.ant-input {
    height: 120px;
  }
  .ant-select {
    width: 100%;
  }
  .rdw-image-modal {
    left: -35px;
    top: auto;
  }
  .rdw-embedded-modal, .rdw-link-modal {
    height: auto
  }

`;
