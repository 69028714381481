import { gql } from '@apollo/react-hooks';
import { UserFragment } from './fragments/user';
import { Role, Session } from '../types/auth';
import { Connection } from '../types/parse';

export interface LoginVariables {
  username: string;
  password: string;
}

export interface LoginResponse {
  logIn: {
    viewer: Session;
  };
}

export const LoginQuery = gql`
  mutation LogIn($username: String!, $password: String!) {
    logIn(input: { username: $username, password: $password }) {
      clientMutationId
      viewer {
        sessionToken
        user {
          ...UserFragment
        }
      }
    }
  }
  ${UserFragment}
`;

export const LogoutQuery = gql`
  mutation LogOut($id: String) {
    logOut(input: { clientMutationId: $id }) {
      clientMutationId
      ok
    }
  }
`;

export interface ViewerResponse {
  viewer: Session;
}

export const ViewerQuery = gql`
  query viewer {
    viewer {
      sessionToken
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export interface GetRolesByUserIdVariables {
  id: string;
}

export interface GetRolesByUserIdResponse {
  roles: Connection<Role>;
}

export const GetRolesByUserIdQuery = gql`
  query getRoles($id: ID!) {
    roles(where: { users: { have: { id: { equalTo: $id } } } }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export interface ResetPasswordPayload {
  email: string;
}

export interface ResetPasswordResponse {
  resetPassword: {
    ok: boolean;
  };
}

export const ResetPasswordQuery = gql`
  mutation resetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      ok
    }
  }
`;
export interface ChangePasswordPayload {
  id: string;
  token: string;
  password: string;
}

export interface ChangePasswordResponse {
  changePassword: {
    ok: boolean;
  };
}

export const ChangePasswordQuery = gql`
  mutation changePassword($token: String!, $id: ID!, $password: String!) {
    changePassword(input: { token: $token, id: $id, password: $password }) {
      ok
    }
  }
`;
