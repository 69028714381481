import { gql } from '@apollo/react-hooks';
import { GQLCreateEventInput, GQLPartnerPointerInput, GQLAny } from '../graphql.schema';
import { Connection } from '../types/parse';
import { Project } from '../types/projects';
import { ProjectFragment } from './fragments/project';

export interface GetProjectsRes {
  projects: Connection<Project>;
}
export interface GetProjectsParams {
  order?: Array<string>;
  where?: {
    name?: {
      equalTo?: string;
    };
  };
}

export const GetProjectsQuery = gql`
  query projects($order: [ProjectOrder!], $where: ProjectWhereInput) {
    projects(order: $order, where: $where) {
      edges {
        node {
          ...ProjectFragment
        }
      }
    }
  }
  ${ProjectFragment}
`;

export interface AddProjectParams extends GQLCreateEventInput {
  website?: string;
}

export interface AddProjectRes {
  createProject: {
    project: Project;
  };
}

export const AddProjectQuery = gql`
  mutation createProject(
    $logo: AppFilePointerInput
    $picture: AppFilePointerInput
    $name: String
    $showOnMainPage: Boolean
    $website: String
    $Partner: PartnerPointerInput
    $Team: [Any]
    $Technologies: [Any]
  ) {
    createProject(
      input: {
        fields: {
          logo: $logo
          picture: $picture
          name: $name
          website: $website
          showOnMainPage: $showOnMainPage
          Partner: $Partner
          Team: $Team
          Technologies: $Technologies
        }
      }
    ) {
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

export interface GetProjectParams {
  id: string;
}

export interface GetProjectRes {
  project: Project;
}

export const GetProjectQuery = gql`
  query projects($id: ID!) {
    project(id: $id) {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export interface DeleteProjectParams {
  id: string;
}

export interface DeleteProjectRes {
  deleteProject: {
    project: Project;
  };
}

export const DeleteProjectQuery = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(input: { id: $id }) {
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

export interface EditProjectParams {
  id?: string;
  name?: string;
  showOnMainPage?: boolean;
  website?: string;
  logo?: {
    link: string;
  };
  picture?: {
    link: string;
  };
  Partner?: GQLPartnerPointerInput;
  Team?: Array<GQLAny | null>;
  Technologies?: Array<GQLAny | null>;
  alias?: string;
  showOnPartner?: boolean;
  titleColor?: string;
  type?: string[];
}

export interface EditProjectRes {
  updateProject: {
    project: Project;
  };
}

export const EditProjectQuery = gql`
  mutation updateProject($id: ID!, $fields: UpdateProjectFieldsInput) {
    updateProject(input: { id: $id, fields: $fields }) {
      project {
        objectId
      }
    }
  }
`;
