// export const editorConfigOld = {
//   reactIgnoreAttrs: ['class', 'id'],
//   tableStyles: {
//     'no-border': 'No border',
//   },
//   useClasses: false,
//   attribution: false,
//   toolbarSticky: false,
//   charCounterCount: false,
//   toolbarVisibleWithoutSelection: true,
//   fontSizeSelection: true,
//   linkInsertButtons: [],
//   linkList: [],
//   paragraphFormatSelection: true,
//   paragraphFormat: {
//     N: 'Normal',
//     H1: 'Heading 1',
//     H2: 'Heading 2',
//     H3: 'Heading 3',
//     H4: 'Heading 4',
//     H5: 'Heading 5',
//     H6: 'Heading 6',
//     PRE: 'Code',
//   },
//   imageUpload: true,
//   fontSizeDefaultSelection: '16',
//   fontSize: [
//     '8',
//     '9',
//     '10',
//     '11',
//     '12',
//     '13',
//     '14',
//     '15',
//     '16',
//     '17',
//     '18',
//     '19',
//     '20',
//     '21',
//     '22',
//     '23',
//     '24',
//     '25',
//     '26',
//     '27',
//     '28',
//     '29',
//     '30',
//     '36',
//     '48',
//     '60',
//     '72',
//     '96',
//   ],

//   toolbarButtons: {
//     moreText: {
//       buttons: [
//         'bold',
//         'italic',
//         'underline',
//         'strikeThrough',
//         'subscript',
//         'superscript',
//         'textColor',
//         'inlineClass',
//         'clearFormatting',
//       ],
//       buttonsVisible: 0,
//     },
//     moreParagraph: {
//       buttons: [
//         'alignLeft',
//         'alignCenter',
//         'alignRight',
//         'alignJustify',
//         'formatOL',
//         'formatUL',
//         'fontSize',
//         'paragraphFormat',
//         'paragraphStyle',
//         'lineHeight',
//         'quote',
//         'clearFormatting',
//       ],
//       buttonsVisible: 0,
//     },
//     moreRich: {
//       buttons: ['insertLink', 'insertImage', 'insertHR', 'insertVideo', 'insertTable'],
//       buttonsVisible: 0,
//     },
//     moreMisc: {
//       buttons: ['undo', 'redo', 'selectAll', 'help'],
//       align: 'right',
//       buttonsVisible: 2,
//     },
//   },
//   pluginsEnabled: [
//     'spell',
//     'quote',
//     'save',
//     'fontSize',
//     'paragraphFormat',
//     'paragraphStyle',
//     'help',
//     'align',
//     'link',
//     'lists',
//     'file',
//     'url',
//     'video',
//     'colors',
//     'entities',
//     'inlineClass',
//     'inlineStyle',
//     'imageTUI',
//     'table',
//   ],
// };

export const editorConfig = {
  reactIgnoreAttrs: ['class', 'id'],
  tableStyles: {
    'no-border': 'No border',
  },
  useClasses: false,
  attribution: false,
  toolbarSticky: false,
  charCounterCount: false,
  toolbarVisibleWithoutSelection: true,
  fontSizeSelection: true,
  linkInsertButtons: [],
  linkList: [],
  paragraphFormatSelection: true,
  paragraphFormat: {
    N: 'Normal',
    H1: 'Heading 1',
    H2: 'Heading 2',
    H3: 'Heading 3',
    H4: 'Heading 4',
    H5: 'Heading 5',
    H6: 'Heading 6',
  },
  imageUpload: true,
  fontSizeDefaultSelection: '16',

  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'textColor',
        'clearFormatting',
      ],
      buttonsVisible: 0,
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'clearFormatting',
      ],
      buttonsVisible: 0,
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'selectAll', 'help'],
      align: 'right',
      buttonsVisible: 2,
    },
    moreRich: {
      buttons: ['insertLink', 'insertImage', 'insertHR', 'insertVideo', 'insertTable'],
      align: 'left',
      buttonsVisible: 0,
    },
  },
  pluginsEnabled: [
    'spell',
    'quote',
    'save',
    'fontSize',
    'paragraphFormat',
    'paragraphStyle',
    'help',
    'align',
    'link',
    'lists',
    'file',
    'url',
    'video',
    'colors',
    'entities',
    'inlineClass',
    'inlineStyle',
    'imageTUI',
    'table',
  ],
};

export enum LayoutTypes {
  left = 'left',
  right = 'right',
  center = 'center',
}

export const layouts = [LayoutTypes.left, LayoutTypes.center, LayoutTypes.right];
