import React, { useEffect, useState } from 'react';
import { ListContainer } from '../common/ListContainer';
import { AddButton } from '../common/ActionsButtons';
import { routes } from '../../constants/routes';
import { Partner } from '../../types/partners';
import { RearrangeData } from '../../hooks/list';
import { DeletePartnerParams } from '../../queries/partners';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { ItemType } from '../../types/common';
import { DragSortingTable } from '../Table';

interface IProps {
  items?: Array<Partner>;
  isLoading?: boolean;
  rearrangeData: RearrangeData<Partner>;
  deletePartner?: (params: DeletePartnerParams) => Promise<void>;
}

export const Partners: React.FC<IProps> = ({ rearrangeData, isLoading, deletePartner }) => {
  const { items, moveUp, moveDown, saveOrder } = rearrangeData;
  const [isSave, setIsSave] = useState(0);

  const handleMoveUp = (id?: string) => {
    if (id) return () => moveUp(id);
  };
  const handleMoveDown = (id?: string) => {
    if (id) return () => moveDown(id);
  };

  const handleDelete = (id?: string) => {
    if (id) return () => confirm(confirmDelete('partner'), () => deletePartner?.({ id }));
  };

  useEffect(() => {
    if (!isSave || !items.length) return;
    saveOrder();
  }, [isSave]);

  const extras = [<AddButton key={Math.random()} to={routes.addPartner} />];

  return (
    <ListContainer title="Partners" extra={extras}>
      <DragSortingTable
        view={routes.viewPartner}
        edit={routes.editPartner}
        onMoveUp={handleMoveUp}
        onMoveDown={handleMoveDown}
        items={items as ItemType[]}
        onDelete={handleDelete}
        loading={!!isLoading}
        saveOrder={setIsSave}
      />
    </ListContainer>
  );
};
