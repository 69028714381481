import React from 'react';
import { useFetchSession, useLogout } from '../../hooks/auth';
import { Layout } from '../../components/Layout';

export const LayoutApp = () => {
  const { logout } = useLogout();
  const { session } = useFetchSession();

  return <Layout logout={logout} username={session?.user?.username} />;
};
