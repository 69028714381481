import React, { useState } from 'react';
import { Team } from '../../components/Team';
import { useDeleteTeam, useGetTeamsList } from '../../hooks/teams';
import { useRearrangeList } from '../../hooks/list';
import { ItemType } from '../../constants/common';
import { Teammate } from '../../types/team';

export const TeamContainer = () => {
  const [value, setValue] = useState('');
  const { data, loading, refetch } = useGetTeamsList(value);
  const { deleteTeam } = useDeleteTeam({
    onsuccessCb: refetch,
  });

  const rearrangeData = useRearrangeList<Teammate>({
    items: data,
    itemType: ItemType.Team,
    onsaveCb: refetch,
  });

  const isLoading = loading || rearrangeData.orderData.loading;

  return <Team deleteTeam={deleteTeam} rearrangeData={rearrangeData} isLoading={isLoading} setValue={setValue} />;
};
