import React from 'react';
import { BaseButton } from './BaseButton';
import { ActionButton } from './types';

export const DeleteButton: React.FC<ActionButton> = (props) => {
  return (
    <BaseButton ghost danger {...props}>
      delete
    </BaseButton>
  );
};
