import React, { useState, useEffect, MouseEventHandler, Dispatch, SetStateAction } from 'react';
import { Button, Card, Table } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CloseOutlined } from '@ant-design/icons';
import { ItemType } from '../../types/common';
import { DraggableBodyRow } from '.';
import { SectionTitle } from '../../types/sections';
import { HTMLEditor } from '../Editor';

type DataType = {
  key: string;
  type?: string;
  layoutType?: string;
  data?: string;
  objectId: string;
  onDelete?: (id: string) => MouseEventHandler<HTMLElement> | undefined;
  editSection?: (id: string, data: string, layoutType?: string) => Promise<void>;
  disabled?: boolean;
  titleColor?: string;
};

const headStyle = { backgroundColor: '#f5f5f5', border: '2px solid darkgray' };
const bodyStyle = { border: '2px solid darkgray' };

const columns = [
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
    width: '100%',
    render: (_: any, item: DataType) => (
      <Card
        title={SectionTitle[item.type as keyof typeof SectionTitle]}
        headStyle={headStyle}
        bodyStyle={bodyStyle}
        extra={
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={item.onDelete?.(item?.objectId)}
            disabled={item.disabled}
            danger
          >
            Delete Section
          </Button>
        }
      >
        {item.type !== 'html' ? (
          <p align="center">{`<--- ${SectionTitle[item.type as keyof typeof SectionTitle]} section --->`}</p>
        ) : (
          <HTMLEditor
            layoutType={item.layoutType}
            content={item.data}
            saveContent={(data, layoutType) => item.editSection?.(item.objectId, data, layoutType)}
            disabled={item.disabled}
            titleColor={item?.titleColor}
          />
        )}
      </Card>
    ),
  },
];

interface Props {
  items: ItemType[];
  onDelete: (id: string) => MouseEventHandler<HTMLElement> | undefined;
  onMoveUp: (id: string) => void;
  onMoveDown: (id: string, count: number) => void;
  saveOrder: Dispatch<SetStateAction<number>>;
  editSection?: (id: string, data: string) => Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  titleColor?: string;
}

export const SectionsSortingTable: React.FC<Props> = ({
  items,
  onDelete,
  onMoveUp,
  onMoveDown,
  saveOrder,
  editSection,
  disabled,
  loading,
  titleColor,
}) => {
  const [data, setData] = useState<Array<DataType>>([]);
  useEffect(() => {
    setData(
      items.map((item: ItemType) => ({
        key: item.objectId,
        type: item?.type,
        layoutType: item?.layoutType,
        data: item?.data,
        action: true,
        objectId: item?.objectId,
        onDelete,
        editSection,
        disabled,
        titleColor,
      }))
    );
  }, [items, titleColor]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        loading={loading}
        showHeader={false}
        pagination={false}
        columns={columns}
        dataSource={data}
        components={components}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRow={(record, index) => ({
          record,
          index,
          onMoveUp,
          onMoveDown,
          data,
          saveOrder,
        })}
      />
    </DndProvider>
  );
};
