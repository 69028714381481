import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { routes } from './constants/routes';
import {
  AddTechnology,
  EditTechnology,
  TechnologiesContainer,
  ViewTechnology,
} from './containers/TechnologiesContainer';
import { AddTeammate, EditTeammate, TeamContainer, ViewTeammate } from './containers/TeamContainer';
import { AddEvent, EditEvent, EventsContainer, ViewEvent } from './containers/EventsContainer';
import { AddProject, EditProject, ProjectsContainer, ViewProject } from './containers/ProjectsContainer';
import { AddPartner, EditPartner, PartnersContainer, ViewPartner } from './containers/PartnersContainer';
import { LayoutApp } from './containers/LayoutContainer/LayoutAppContainer';

export const PublicRoutes = (
  <>
    <Route path={routes.technologies} element={<TechnologiesContainer />} />
    <Route path={`${routes.viewTechnology}/:id`} element={<ViewTechnology />} />
    <Route path={`${routes.editTechnology}/:id`} element={<EditTechnology />} />
    <Route path={routes.addTechnology} element={<AddTechnology />} />
    <Route path={routes.team} element={<TeamContainer />} />
    <Route path={`${routes.viewTeammate}/:id`} element={<ViewTeammate />} />
    <Route path={`${routes.editTeammate}/:id`} element={<EditTeammate />} />
    <Route path={routes.addTeammate} element={<AddTeammate />} />
    <Route path={routes.events} element={<EventsContainer />} />
    <Route path={`${routes.viewEvent}/:id`} element={<ViewEvent />} />
    <Route path={`${routes.editEvent}/:id`} element={<EditEvent />} />
    <Route path={routes.addEvent} element={<AddEvent />} />
    <Route path={routes.projects} element={<ProjectsContainer />} />
    <Route path={`${routes.viewProject}/:id`} element={<ViewProject />} />
    <Route path={`${routes.editProject}/:id`} element={<EditProject />} />
    <Route path={routes.addProject} element={<AddProject />} />
    <Route path={routes.partners} element={<PartnersContainer />} />
    <Route path={`${routes.viewPartner}/:id`} element={<ViewPartner />} />
    <Route path={`${routes.editPartner}/:id`} element={<EditPartner />} />
    <Route path={routes.addPartner} element={<AddPartner />} />
    <Route path={routes.any} element={<Navigate to={routes.technologies} />} />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.main} element={<LayoutApp />}>
      {PublicRoutes}
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
