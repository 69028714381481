import React, { useState } from 'react';
import { Events } from '../../components/Events';
import { IEvent } from '../../types/events';
import { useDeleteEvent, useGetEventsList } from '../../hooks/events';
import { useRearrangeList } from '../../hooks/list';
import { ItemType } from '../../constants/common';

export const EventsContainer = () => {
  const [value, setValue] = useState('');
  const [date, setDate] = useState<Date | string>();
  const { data, refetch, loading } = useGetEventsList(value, date);
  const { deleteEvent } = useDeleteEvent({
    onsuccessCb: refetch,
  });
  const rearrangeData = useRearrangeList<IEvent>({
    items: data,
    itemType: ItemType.Event,
    onsaveCb: refetch,
  });

  const isLoading = loading || rearrangeData.orderData.loading;

  return (
    <Events
      rearrangeData={rearrangeData}
      deleteEvent={deleteEvent}
      isLoading={isLoading}
      setValue={setValue}
      setDate={setDate}
    />
  );
};
