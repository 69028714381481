import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { ViewContainer } from '../common/ViewContainer';
import { routes } from '../../constants/routes';
import { FormModes } from '../../constants/common';
import { AlignedBlock } from '../common/AlignedBlock';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { Br } from '../common/Br';
import { IEvent } from '../../types/events';
import { DatePicker } from '../common/DatePicker';
import { AddEventFnParams, EditEventFnParams } from '../../hooks/events';
import { DeleteEventParams } from '../../queries/events';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { Project } from '../../types/projects';
import { FroalaEditor } from '../Editor/FroalaEditor';
import { compareObject, parseEvent } from '../../helpers/form';
import { BlockerComponent } from '../common/Blocker';
import { useRemovePb } from '../../hooks/sections';
import { filterPB } from '../../helpers/common';

const dateFormat = 'YYYY/MM';

const titles: Record<FormModes, string> = {
  [FormModes.View]: 'Event',
  [FormModes.Edit]: 'Edit Event',
  [FormModes.Add]: 'Add Event',
};

interface IProps {
  mode: FormModes;
  item?: IEvent;
  loading?: boolean;
  addEvent?: (params: AddEventFnParams) => Promise<void>;
  editEvent?: (params: EditEventFnParams) => Promise<void>;
  deleteEvent?: (params: DeleteEventParams) => Promise<void>;
  projects?: Project[];
}

export const EventForm: React.FC<IProps> = ({ mode, item, addEvent, editEvent, deleteEvent, loading, projects }) => {
  const [model, setModel] = useState(item?.descr || '');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isBlocked, setBlocked] = useState(false);
  const parseData = useMemo(() => parseEvent(item), [item]);

  const onBack = () => {
    navigate(routes.events);
  };

  const goToViewMode = () => {
    navigate(`${routes.viewEvent}/${item?.objectId}`);
  };
  const goToEditMode = () => {
    navigate(`${routes.editEvent}/${item?.objectId}`);
  };
  const goToList = () => {
    navigate(routes.events);
  };

  const onCreate = (values: AddEventFnParams) => {
    addEvent?.(values).then(goToList);
  };
  const onSave = (values: EditEventFnParams) => {
    const id = item?.objectId;
    if (!id) return;

    editEvent?.({ id, ...values })
      .then(() => setBlocked(false))
      .then(goToList);
  };

  const onFinish = (values: AddEventFnParams | EditEventFnParams) => {
    const withoutPB = filterPB(model);
    if (mode === FormModes.Add) return onCreate({ ...values, descr: withoutPB });
    if (mode === FormModes.Edit) return onSave({ ...values, descr: withoutPB });
  };

  const onCancel = () => {
    goToViewMode();
  };
  const onDelete = () => {
    const id = item?.objectId;

    if (id)
      confirm(confirmDelete('team'), async () => {
        await deleteEvent?.({ id });
        onBack();
      });
  };

  const onModelChange = (state: string) => {
    setModel(state);
  };

  useEffect(() => {
    if (item?.descr) {
      setModel(item?.descr);
    }
  }, [item?.descr]);
  const onCompare = () => {
    const value = !compareObject(form.getFieldsValue(), parseData);
    setBlocked((prev) => (prev === value ? prev : value));
  };
  const extra: Array<ReactNode> =
    mode === FormModes.View
      ? [
          <EditButton key={Math.random()} onClick={goToEditMode} />,
          <DeleteButton key={Math.random()} onClick={onDelete} />,
        ]
      : [];

  const disabled = mode === FormModes.View;
  useRemovePb(model);
  return (
    <ViewContainer
      loading={loading}
      onBack={mode === FormModes.Edit ? onCancel : onBack}
      extra={extra}
      title={titles[mode]}
    >
      {mode === FormModes.Edit && <BlockerComponent isBlocked={isBlocked} form={form} object={parseData} />}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onCompare}
        initialValues={{
          date: item?.date ? parseISO(String(item?.date)) : undefined,
          name: item?.name,
          descr: model,
          showOnMainPage: item?.showOnMainPage,
          showOnPartnersPage: item?.showOnPartnersPage,
          Project: item?.Project?.objectId,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('Name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={disabled} name="name" />
        </Form.Item>
        <Form.Item label="Description" name="descr">
          <FroalaEditor onModelChange={onModelChange} model={model} />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          rules={[{ type: 'object' as const, required: true, message: 'Please select date!' }]}
        >
          <DatePicker disabled={disabled} picker="month" format={dateFormat} name="date" />
        </Form.Item>
        <Form.Item valuePropName="checked" name="showOnMainPage">
          <Checkbox disabled={disabled} name="showOnMainPage">
            Show on Main Page
          </Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked" name="showOnPartnersPage">
          <Checkbox disabled={disabled} name="showOnPartnersPage">
            Show on Partner Page
          </Checkbox>
        </Form.Item>
        <Form.Item label="Related project" name="Project">
          <Select showSearch placeholder="Select a project" optionFilterProp="children">
            {projects?.map((el) => (
              <Select.Option value={el.objectId}>{el.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        {mode === FormModes.Edit && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button onClick={onCancel}>Cancel</Button>
              <Br hrIndent={8} />
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
        {mode === FormModes.Add && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
      </Form>
    </ViewContainer>
  );
};
