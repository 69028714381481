import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { order } from '../constants/common';
import {
  AddProjectParams,
  AddProjectQuery,
  AddProjectRes,
  DeleteProjectParams,
  DeleteProjectQuery,
  DeleteProjectRes,
  EditProjectParams,
  EditProjectQuery,
  EditProjectRes,
  GetProjectParams,
  GetProjectQuery,
  GetProjectRes,
  GetProjectsParams,
  GetProjectsQuery,
  GetProjectsRes,
} from '../queries/projects';
import { AppFile, LoadFile } from '../types/common';
import {
  CreateAppFileQuery,
  CreateFileParams,
  CreateFileRes,
  UpdateAppFileQuery,
  UpdateFileParams,
  UpdateFileRes,
} from '../queries/appfile';
import {
  GQLAppFilePointerInput,
  GQLCreateProjectFieldsInput,
  GQLCreateProjectInput,
  GQLUpdateProjectInput,
} from '../graphql.schema';
import { changeFileName } from '../helpers/common';

export const useGetProjectsList = (value?: string) => {
  const where = value ? { where: { name: { equalTo: value } } } : null;
  const { data, ...otherData } = useQuery<GetProjectsRes, GetProjectsParams>(GetProjectsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      order: [order.layOut],
      ...where,
    },
  });

  const _data = useMemo(() => data?.projects.edges.map((el) => el.node), [data]);

  return { data: _data, ...otherData };
};

export type AddProjectType = GQLCreateProjectFieldsInput;

export const useAddProject = (options?: { cb?: (id: string) => void }) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddProjectRes, AddProjectParams>(AddProjectQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);

  const addProject = async (_params: AddProjectType) => {
    const { logo, picture, ...params } = _params;
    try {
      let logoInfo: AppFile | undefined;
      let pictureInfo: AppFile | undefined;

      if (logo) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(logo as File),
          } as GQLAppFilePointerInput,
        });
        logoInfo = response.data?.createAppFile.appFile;
      }
      if (picture) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(picture as File),
          } as GQLAppFilePointerInput,
        });
        pictureInfo = response.data?.createAppFile.appFile;
      }

      const { data } = await add({
        variables: {
          ...params,
          ...(logoInfo?.objectId
            ? {
                logo: {
                  link: logoInfo.objectId,
                },
              }
            : {}),
          ...(pictureInfo?.objectId
            ? {
                picture: {
                  link: pictureInfo.objectId,
                },
              }
            : {}),
          ...(params?.Partner ? { Partner: { link: params.Partner } } : {}),
          Team: params?.Team?.map((el) => ({ __type: 'Pointer', className: 'Team', objectId: el })),
          Technologies: params?.Technologies?.map((el) => ({
            __type: 'Pointer',
            className: 'Technology',
            objectId: el,
          })),
        } as GQLCreateProjectInput,
      });
      const id = data?.createProject.project.objectId;
      if (id) cb?.(id);
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { createProject: addProject, ...otherData };
};

export const useGetProject = () => {
  const { id } = useParams<{ id: string }>();
  const { data, ...otherData } = useQuery<GetProjectRes, GetProjectParams>(GetProjectQuery, {
    variables: { id: id || '' },
  });

  return { data: data?.project, ...otherData };
};

export const useDeleteProject = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeleteProjectRes, DeleteProjectParams>(DeleteProjectQuery);

  const deleteProject = async (params: DeleteProjectParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deleteProject, otherData };
};

export type EditProjectType = Omit<EditProjectParams, 'logo' | 'picture'> & {
  logo?: { logoFile: LoadFile; id?: string };
  picture?: { pictureFile: LoadFile; id?: string };
};

export const useEditProject = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [edit, otherData] = useMutation<EditProjectRes, EditProjectParams>(EditProjectQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);
  const [updateAppFile] = useMutation<UpdateFileRes, UpdateFileParams>(UpdateAppFileQuery);

  const editProject = async (_params: EditProjectType) => {
    const { logo, picture, ...params } = _params;
    const { id, logoFile } = logo || {};
    const { id: picId, pictureFile: picFile } = picture || {};

    try {
      let logoInfo: AppFile | undefined;
      let pictureInfo: AppFile | undefined;

      if (logoFile && !id) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(logoFile),
          } as GQLAppFilePointerInput,
        });

        logoInfo = response.data?.createAppFile.appFile;
      } else if (logoFile && id) {
        const response = await updateAppFile({
          variables: {
            upload: changeFileName(logoFile),
            id,
          },
        });

        logoInfo = response.data?.UpdateAppFile?.appFile;
      }

      if (picFile && !picId) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(picFile),
          } as GQLAppFilePointerInput,
        });

        pictureInfo = response.data?.createAppFile.appFile;
      } else if (picFile && picId) {
        const response = await updateAppFile({
          variables: {
            upload: changeFileName(picFile),
            id: picId,
          },
        });

        pictureInfo = response.data?.UpdateAppFile?.appFile;
      }
      await edit({
        variables: {
          id: params?.id,
          fields: {
            name: params?.name,
            website: params?.website,
            showOnMainPage: params?.showOnMainPage,
            showOnPartner: params?.showOnPartner,
            alias: params?.alias,
            titleColor: params?.titleColor,
            type: params?.type,
            ...(logoInfo?.objectId
              ? {
                  logo: {
                    link: logoInfo?.objectId,
                  },
                }
              : {}),
            ...(pictureInfo?.objectId
              ? {
                  picture: {
                    link: pictureInfo?.objectId,
                  },
                }
              : {}),
            ...(params?.Partner ? { Partner: { link: params.Partner } } : { Partner: { link: '' } }),
            Team: params?.Team?.map((el) => ({ __type: 'Pointer', className: 'Team', objectId: el })),
            Technologies: params?.Technologies?.map((el) => ({
              __type: 'Pointer',
              className: 'Technology',
              objectId: el,
            })),
          },
        } as unknown as GQLUpdateProjectInput,
      });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editProject, ...otherData };
};
