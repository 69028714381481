import React from 'react';
import { TechnologyForm } from '../../components/Technologies/TechnologyForm';
import { FormModes } from '../../constants/common';
import { useDeleteTechnology, useGetTechnology } from '../../hooks/technologies';

export const ViewTechnology = () => {
  const { data, loading, refetch } = useGetTechnology();
  const { deleteTechnology } = useDeleteTechnology({
    onsuccessCb: () => refetch(),
  });

  return <TechnologyForm loading={loading} mode={FormModes.View} item={data} deleteTechnology={deleteTechnology} />;
};
