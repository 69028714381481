import lodash from 'lodash';
import { parseISO } from 'date-fns';
import { Teammate } from '../types/team';
import { Technology } from '../types/technologies';
import { Project } from '../types/projects';
import { IEvent } from '../types/events';
import { Partner } from '../types/partners';

export const parseTeammate = (teammate?: Teammate) => {
  return {
    showInTeam: teammate?.showInTeam,
    showInContacts: teammate?.showInContacts,
    qr: teammate?.qr,
    email: teammate?.email,
    firstName: teammate?.firstName,
    lastName: teammate?.lastName,
    phone: teammate?.phone,
    position: teammate?.position,
    birthDate: parseISO(String(teammate?.birthDate)),
    avatar: teammate?.avatar,
  };
};

export const parseTechnology = (technology?: Technology) => {
  return {
    logo: technology?.logo,
    name: technology?.name,
    url: technology?.url,
    showOnMainPage: technology?.showOnMainPage,
  };
};

export const parseEvent = (event?: IEvent) => {
  return {
    Project: event?.Project?.objectId,
    date: parseISO(String(event?.date)),
    descr: event?.descr,
    name: event?.name,
    showOnMainPage: event?.showOnMainPage,
    showOnPartnersPage: event?.showOnPartnersPage,
  };
};

export const parseProject = (project?: Project) => {
  return {
    Partner: project?.Partner?.objectId,
    Team: project?.Team?.map((teammate) => teammate.objectId),
    Technologies: project?.Technologies?.map((teammate) => teammate.objectId),
    alias: project?.alias,
    logo: project?.logo,
    name: project?.name,
    picture: project?.picture,
    showOnMainPage: project?.showOnMainPage,
    website: project?.website,
    type: project?.type?.map((element) => element.value),
    showOnPartner: project?.showOnPartner,
  };
};

export const parsePartner = (partner?: Partner) => {
  return {
    alias: partner?.alias,
    descr: partner?.descr,
    logo: partner?.logo,
    name: partner?.name,
    showOnMainPage: partner?.showOnMainPage,
    website: partner?.website,
  };
};

export const compareObject = (firstObject: { [key: string]: any }, secondObject: { [key: string]: any }) => {
  const { avatar, logo, picture, ...firstProps } = firstObject;
  const { avatar: secondAvatar, logo: secondLogo, picture: secondPicture, ...secondProps } = secondObject;
  if (avatar && !lodash.isEqual(avatar, secondAvatar)) return false;
  if (logo && !lodash.isEqual(logo, secondLogo)) return false;
  if (picture && !lodash.isEqual(picture, secondPicture)) return false;
  return lodash.isEqual(firstProps, secondProps);
};
