import React from 'react';
import { PartnerForm } from '../../components/Partners';
import { FormModes, ItemType } from '../../constants/common';
import { useRearrangeList } from '../../hooks/list';
import { useEditPartner, useGetPartner } from '../../hooks/partners';
import { useGetSections, useAddSection, useEditSection, useDeleteSection } from '../../hooks/sections';
import { SectionType, Section } from '../../types/sections';

export const EditPartner = () => {
  const { data, loading, refetch } = useGetPartner();
  const { editPartner } = useEditPartner({ onsuccessCb: () => refetch() });
  const {
    data: sections,
    refetch: sRefetch,
    loading: sectionsLoading,
  } = useGetSections(SectionType.PARTNER, data?.objectId);
  const { addSection } = useAddSection({ cb: () => sRefetch() }, SectionType.PARTNER, data?.objectId);
  const { editSection } = useEditSection({ onsuccessCb: () => sRefetch() });
  const { deleteSection } = useDeleteSection({ onsuccessCb: () => sRefetch() });
  const rearrangeData = useRearrangeList<Section>({
    items: sections,
    itemType: ItemType.Section,
    onsaveCb: sRefetch,
  });

  const isLoading = loading || sectionsLoading;

  return (
    <PartnerForm
      editPartner={editPartner}
      loading={isLoading}
      item={data}
      mode={FormModes.Edit}
      addSection={addSection}
      editSection={editSection}
      deleteSection={deleteSection}
      rearrangeData={rearrangeData}
    />
  );
};
