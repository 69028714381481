import { useMutation, useQuery } from '@apollo/react-hooks';
import { useEffect, useMemo } from 'react';
import { message } from 'antd';
import { order } from '../constants/common';
import { GQLCreateSectionFieldsInput, GQLCreateSectionInput, GQLUpdateSectionInput } from '../graphql.schema';
import { SectionType } from '../types/sections';
import {
  GetSectionsRes,
  GetSectionsParams,
  GetSectionsQuery,
  AddSectionParams,
  AddSectionQuery,
  AddSectionRes,
  EditSectionParams,
  EditSectionQuery,
  EditSectionRes,
  DeleteSectionParams,
  DeleteSectionQuery,
  DeleteSectionRes,
} from '../queries/sections';
import { LayoutTypes } from '../constants/editor';

export const useGetSections = (value?: string, id?: string) => {
  const where =
    value === SectionType.PARTNER
      ? { Partner: { have: { objectId: { equalTo: id } } } }
      : { Project: { have: { objectId: { equalTo: id } } } };
  const { data, ...otherData } = useQuery<GetSectionsRes, GetSectionsParams>(GetSectionsQuery, {
    variables: {
      order: [order.layOut],
      where,
    },
  });

  const _data = useMemo(
    () =>
      data?.sections.edges.map((el) => {
        return {
          ...el.node,
          data: (el?.node?.data || '')?.replace(/style/gi, 'fr-original-style'),
        };
      }),
    [data]
  );

  return { data: _data, ...otherData };
};

export type AddSectionFnParams = GQLCreateSectionFieldsInput;

export const useAddSection = (options?: { cb?: (id: string) => void }, type?: string, typeId?: string) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddSectionRes, AddSectionParams>(AddSectionQuery);

  const addSection = async (_params: AddSectionFnParams) => {
    const { ...params } = _params;

    try {
      const { data } = await add({
        variables: {
          fields: {
            layoutType: LayoutTypes.left,
            type: params?.type,
            data: params?.type === 'html' ? '' : undefined,
            ...(type === SectionType.PARTNER ? { Partner: { link: typeId } } : { Project: { link: typeId } }),
          },
        } as GQLCreateSectionInput,
      });
      const id = data?.createSection.section.objectId;
      if (id) cb?.(id);
      message.success('Section added successfully!');
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { addSection, ...otherData };
};

export type EditSectionFnParams = EditSectionParams;

export const useEditSection = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [edit, otherData] = useMutation<EditSectionRes, EditSectionParams>(EditSectionQuery);

  const editSection = async (id: string, data: string, layoutType?: string) => {
    try {
      await edit({
        variables: {
          id,
          fields: {
            data: data?.replace(/ style=".+?"/gi, '')?.replace(/fr-original-style/gi, 'style'),
            layoutType,
          },
        } as GQLUpdateSectionInput,
      });
      onsuccessCb?.();
      message.success('Content saved successfully!');
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editSection, ...otherData };
};

export const useDeleteSection = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeleteSectionRes, DeleteSectionParams>(DeleteSectionQuery);

  const deleteSection = async (params: DeleteSectionParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
      message.info('Section deleted successfully');
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deleteSection, otherData };
};

export const useRemovePb = (model: any) => {
  useEffect(() => {
    Array.from(document.getElementsByClassName('fr-wrapper')).forEach((el) =>
      el.querySelectorAll('a').forEach((link) => link.href.includes('froala.com') && link.remove())
    );
  }, [!!model]);
};
