import { Modal } from 'antd';

export const confirm = (text: string, onOk?: () => void) => {
  return Modal.confirm({
    title: text,
    onOk,
    okText: 'YES',
    cancelText: 'NO',
  });
};
