import { gql } from '@apollo/react-hooks';

export const SectionFragment = gql`
  fragment SectionFragment on Section {
    id
    objectId
    createdAt
    updatedAt
    data
    type
    layoutType
    Partner {
      objectId
    }
    Project {
      objectId
    }
  }
`;
