import React from 'react';
import { Partners } from '../../components/Partners';
import { useRearrangeList } from '../../hooks/list';
import { ItemType } from '../../constants/common';
import { useDeletePartner, useGetPartnersList } from '../../hooks/partners';
import { Partner } from '../../types/partners';

export const PartnersContainer = () => {
  const { data, loading, refetch } = useGetPartnersList();

  const { deletePartner } = useDeletePartner({ onsuccessCb: () => refetch() });

  const rearrangeData = useRearrangeList<Partner>({
    items: data,
    itemType: ItemType.Partner,
    onsaveCb: refetch,
  });

  const isLoading = loading || rearrangeData.orderData.loading;

  return <Partners deletePartner={deletePartner} rearrangeData={rearrangeData} isLoading={isLoading} />;
};
