import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { PlusOutlined } from '@ant-design/icons';
import { parseISO } from 'date-fns';
import { ViewContainer } from '../common/ViewContainer';
import { routes } from '../../constants/routes';
import { FormModes } from '../../constants/common';
import { AlignedBlock } from '../common/AlignedBlock';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { Br } from '../common/Br';
import { Teammate } from '../../types/team';
import { DatePicker } from '../common/DatePicker';
import { Upload } from '../common/Upload';
import { AddTeamType, EditTeamType } from '../../hooks/teams';
import { FileStatus } from '../../constants/file';
import { DeleteTeamParams } from '../../queries/teams';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { Img } from '../common/Img';
import { uploadValidation } from '../../helpers/validators';
import { dummyRequest } from '../../helpers/common';
import { BlockerComponent } from '../common/Blocker';
import { compareObject, parseTeammate } from '../../helpers/form';

const { TextArea } = Input;

const titles: Record<FormModes, string> = {
  [FormModes.View]: 'Employee',
  [FormModes.Edit]: 'Edit Employee',
  [FormModes.Add]: 'Add Employee',
};

interface IProps {
  mode: FormModes;
  item?: Teammate;
  loading?: boolean;
  createTeam?: (params: AddTeamType) => Promise<void>;
  deleteTeam?: (params: DeleteTeamParams) => Promise<void>;
  editTeam?: (params: EditTeamType) => Promise<void>;
}

export const TeammateForm: React.FC<IProps> = ({ mode, item, createTeam, deleteTeam, loading, editTeam }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState<UploadFile | undefined>(
    item?.avatar?.file.url ? { uid: '1', name: '', url: item.avatar.file.url } : undefined
  );
  const [isBlocked, setBlocked] = useState(false);
  const parseData = useMemo(() => parseTeammate(item), [item]);

  const onBack = () => {
    navigate(routes.team);
  };

  const goToViewMode = () => {
    navigate(`${routes.viewTeammate}/${item?.objectId}`);
  };
  const goToEditMode = () => {
    navigate(`${routes.editTeammate}/${item?.objectId}`);
  };
  const goToList = () => {
    navigate(routes.team);
  };

  const onCreate = (values: AddTeamType) => {
    createTeam?.(values).then(goToList);
  };
  const onSave = (values: EditTeamType) => {
    const id = item?.objectId;
    if (!id) return;

    editTeam?.({ id, ...values })
      .then(() => setBlocked(false))
      .then(goToList);
  };

  const onFinish = (values: any) => {
    const fileIsDeleted = values.avatar?.file?.status === FileStatus.removed;
    const file = fileIsDeleted ? null : values.avatar?.file?.originFileObj;
    delete file?.uid;

    if (mode === FormModes.Add) return onCreate({ ...values, avatar: file });
    if (mode === FormModes.Edit) return onSave({ ...values, avatar: { file, id: item?.avatar?.id } });
  };

  const onCancel = () => {
    goToViewMode();
  };
  const onDelete = () => {
    const id = item?.objectId;

    if (id)
      confirm(confirmDelete('team'), async () => {
        await deleteTeam?.({ id });
        onBack();
      });
  };
  const setAvatarFile = (info: UploadChangeParam<UploadFile>) => {
    return info.fileList.length ? setAvatar({ ...info.file, status: 'done' }) : setAvatar(undefined);
  };

  const extra: Array<ReactNode> =
    mode === FormModes.View
      ? [
          <EditButton key={Math.random()} onClick={goToEditMode} />,
          <DeleteButton key={Math.random()} onClick={onDelete} />,
        ]
      : [];
  const onCompare = () => {
    const value = !compareObject(form.getFieldsValue(), parseData);
    setBlocked((prev) => (prev === value ? prev : value));
  };
  useEffect(() => {
    const url = item?.avatar?.file.url;
    if (url) setAvatar({ uid: url, url, name: '' });
  }, [item]);

  return (
    <ViewContainer
      loading={loading}
      onBack={mode === FormModes.Edit ? onCancel : onBack}
      extra={extra}
      title={titles[mode]}
    >
      {mode === FormModes.Edit && <BlockerComponent isBlocked={isBlocked} form={form} object={parseData} />}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onCompare}
        initialValues={{
          showInTeam: item?.showInTeam,
          showInContacts: item?.showInContacts,
          birthDate: item?.birthDate ? parseISO(String(item?.birthDate)) : undefined,
          lastName: item?.lastName,
          firstName: item?.firstName,
          email: item?.email,
          phone: item?.phone,
          position: item?.position,
          qr: item?.qr,
        }}
      >
        <Form.Item
          label="Avatar"
          name="avatar"
          rules={
            !avatar
              ? [
                  {
                    required: true,
                    message: 'Please input avatar!',
                  },
                ]
              : undefined
          }
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={avatar ? [avatar] : []}
            showUploadList
            onChange={setAvatarFile}
            disabled={mode === FormModes.View}
            name="avatar"
            customRequest={dummyRequest}
            beforeUpload={uploadValidation}
          >
            {!avatar && mode === FormModes.View ? (
              <Img />
            ) : (
              !avatar && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Firstname"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please input your first name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('First name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={mode === FormModes.View} name="firstName" />
        </Form.Item>
        <Form.Item
          label="Lastname"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Please input your first last name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('Last name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={mode === FormModes.View} name="lastName" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input disabled={mode === FormModes.View} name="email" />
        </Form.Item>
        <Form.Item label="Phone number" name="phone">
          <Input disabled={mode === FormModes.View} name="phone" />
        </Form.Item>
        <Form.Item
          label="Position"
          name="position"
          rules={[
            {
              required: true,
              message: 'Please input teammate position!',
            },
          ]}
        >
          <Input disabled={mode === FormModes.View} name="position" />
        </Form.Item>
        <Form.Item label="QR code info" name="qr">
          <TextArea disabled={mode === FormModes.View} name="position" />
        </Form.Item>
        <Form.Item label="Birthday" name="birthDate">
          <DatePicker disabled={mode === FormModes.View} name="birthDate" />
        </Form.Item>
        <Form.Item valuePropName="checked" name="showInTeam">
          <Checkbox disabled={mode === FormModes.View} name="showInTeam">
            Show in Team
          </Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked" name="showInContacts">
          <Checkbox disabled={mode === FormModes.View} name="showInContacts">
            Show in Contacts
          </Checkbox>
        </Form.Item>

        {mode === FormModes.Edit && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button onClick={onCancel}>Cancel</Button>
              <Br hrIndent={8} />
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
        {mode === FormModes.Add && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
      </Form>
    </ViewContainer>
  );
};
