import React from 'react';
import { FormModes, ItemType } from '../../constants/common';
import { ProjectForm } from '../../components/Projects';
import { useDeleteProject, useGetProject } from '../../hooks/projects';
import { useGetPartnersList } from '../../hooks/partners';
import { useGetTeamsList } from '../../hooks/teams';
import { useGetTechnologiesList } from '../../hooks/technologies';
import { useGetSections } from '../../hooks/sections';
import { Section, SectionType } from '../../types/sections';
import { useRearrangeList } from '../../hooks/list';

export const ViewProject = () => {
  const { refetch, loading, data } = useGetProject();
  const { deleteProject } = useDeleteProject({ onsuccessCb: () => refetch() });
  const { data: partners } = useGetPartnersList();
  const { data: team } = useGetTeamsList();
  const { data: technologies } = useGetTechnologiesList();
  const { data: sections, refetch: sRefetch } = useGetSections(SectionType.PROJECT, data?.objectId);
  const rearrangeData = useRearrangeList<Section>({
    items: sections,
    itemType: ItemType.Section,
    onsaveCb: sRefetch,
  });

  return (
    <ProjectForm
      deleteProject={deleteProject}
      mode={FormModes.View}
      item={data}
      loading={loading}
      partners={partners}
      team={team}
      technologies={technologies}
      rearrangeData={rearrangeData}
    />
  );
};
