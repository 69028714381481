import React from 'react';
import { FormModes } from '../../constants/common';
import { ProjectForm } from '../../components/Projects';
import { useAddProject, useGetProjectsList } from '../../hooks/projects';
import { useGetPartnersList } from '../../hooks/partners';
import { useGetTeamsList } from '../../hooks/teams';
import { useGetTechnologiesList } from '../../hooks/technologies';

export const AddProject = () => {
  const { refetch } = useGetProjectsList();
  const { createProject } = useAddProject({ cb: () => refetch() });
  const { data: partners } = useGetPartnersList();
  const { data: team } = useGetTeamsList();
  const { data: technologies } = useGetTechnologiesList();

  return (
    <ProjectForm
      mode={FormModes.Add}
      createProject={createProject}
      partners={partners}
      team={team}
      technologies={technologies}
    />
  );
};
