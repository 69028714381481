import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Spin, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Form, FormItem, FormWrapper, PageWrapper } from './styles';
import { AlignedBlock } from '../common/AlignedBlock';

interface Props {
  loading: boolean;
  onSubmit: (email: string) => void;
}

export const RestorePassword: React.FC<Props> = ({ loading, onSubmit }) => {
  const handleSubmit = (values: { email: string }) => {
    onSubmit(values.email);
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Form size="middle" onFinish={handleSubmit as (values: unknown) => void} layout="vertical">
          <Spin spinning={loading}>
            <Typography.Title level={2}>Restore password</Typography.Title>
            <FormItem
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input placeholder="Email" prefix={<MailOutlined />} />
            </FormItem>
            <FormItem>
              <AlignedBlock variant="space-between">
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
                <Link to="/">
                  <Button type="link" htmlType="button">
                    Cancel
                  </Button>
                </Link>
              </AlignedBlock>
            </FormItem>
          </Spin>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};
