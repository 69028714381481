import { gql } from '@apollo/react-hooks';
import { TeamFragment } from './fragments/team';
import { Connection } from '../types/parse';
import { Teammate } from '../types/team';

export interface GetTeamsRes {
  teams: Connection<Teammate>;
}
export interface GetTeamsParams {
  order?: Array<string>;
  where?: {
    firstName?: {
      equalTo?: string;
    };
  };
}

export const GetTeamsQuery = gql`
  query teams($order: [TeamOrder!], $where: TeamWhereInput) {
    teams(order: $order, where: $where) {
      edges {
        node {
          ...TeamFragment
        }
      }
    }
  }
  ${TeamFragment}
`;

export interface AddTeamParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  birthDate?: Date;
  showInTeam?: boolean;
  showInContacts?: boolean;
  avatar?: {
    link: string;
  };
  position?: string;
  qr?: string;
}

export interface AddTeamRes {
  createTeam: {
    team: Teammate;
  };
}

export const AddTeamQuery = gql`
  mutation createTeam(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $birthDate: Date
    $avatar: AppFilePointerInput
    $showInTeam: Boolean
    $showInContacts: Boolean
    $position: String
    $qr: String
  ) {
    createTeam(
      input: {
        fields: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phone: $phone
          birthDate: $birthDate
          avatar: $avatar
          showInTeam: $showInTeam
          showInContacts: $showInContacts
          position: $position
          qr: $qr
        }
      }
    ) {
      team {
        ...TeamFragment
      }
    }
  }
  ${TeamFragment}
`;

export interface GetTeamParams {
  id: string;
}

export interface GetTeamRes {
  team: Teammate;
}

export const GetTeamQuery = gql`
  query team($id: ID!) {
    team(id: $id) {
      ...TeamFragment
    }
  }
  ${TeamFragment}
`;

export interface DeleteTeamParams {
  id: string;
}

export interface DeleteTeamRes {
  deleteTeam: {
    team: Teammate;
  };
}

export const DeleteTeamQuery = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(input: { id: $id }) {
      team {
        ...TeamFragment
      }
    }
  }
  ${TeamFragment}
`;

export interface EditTeamParams {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  birthDate?: Date;
  showInTeam?: boolean;
  showInContacts?: boolean;
  avatar?: {
    link: string;
  };
  position?: string;
  qr?: string;
}

export interface EditTeamRes {
  updateTeam: {
    team: Teammate;
  };
}

export const EditTeamQuery = gql`
  mutation updateTeam(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $birthDate: Date
    $avatar: AppFilePointerInput
    $showInTeam: Boolean
    $showInContacts: Boolean
    $id: ID!
    $position: String
    $qr: String
  ) {
    updateTeam(
      input: {
        id: $id
        fields: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phone: $phone
          birthDate: $birthDate
          avatar: $avatar
          showInTeam: $showInTeam
          showInContacts: $showInContacts
          position: $position
          qr: $qr
        }
      }
    ) {
      team {
        ...TeamFragment
      }
    }
  }
  ${TeamFragment}
`;
