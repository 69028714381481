import React from 'react';
import { PartnerForm } from '../../components/Partners';
import { FormModes, ItemType } from '../../constants/common';
import { useRearrangeList } from '../../hooks/list';
import { useDeletePartner, useGetPartner } from '../../hooks/partners';
import { useGetSections } from '../../hooks/sections';
import { SectionType, Section } from '../../types/sections';

export const ViewPartner = () => {
  const { refetch, loading, data } = useGetPartner();
  const { deletePartner } = useDeletePartner({ onsuccessCb: () => refetch() });
  const { data: sections, refetch: sRefetch } = useGetSections(SectionType.PARTNER, data?.objectId);
  const rearrangeData = useRearrangeList<Section>({
    items: sections,
    itemType: ItemType.Section,
    onsaveCb: sRefetch,
  });

  return (
    <PartnerForm
      deletePartner={deletePartner}
      mode={FormModes.View}
      item={data}
      loading={loading}
      rearrangeData={rearrangeData}
    />
  );
};
