import { gql } from '@apollo/react-hooks';

export const EventFragment = gql`
  fragment EventFragment on Event {
    objectId
    name
    date
    descr
    showOnMainPage
    showOnPartnersPage
    Project {
      objectId
    }
  }
`;
