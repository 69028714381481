import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { ViewContainer } from '../common/ViewContainer';
import { routes } from '../../constants/routes';
import { FormModes } from '../../constants/common';
import { AlignedBlock } from '../common/AlignedBlock';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { Technology } from '../../types/technologies';
import { Br } from '../common/Br';
import { DeleteTechnologyParams } from '../../queries/technologies';
import { confirm } from '../common/Modals';
import { Upload } from '../common/Upload';
import { LoadFile } from '../../types/common';
import { AddTechnologyFnParams, EditTechnologyFnParams } from '../../hooks/technologies';
import { FileStatus } from '../../constants/file';
import { confirmDelete } from '../../constants/modals';
import { Img } from '../common/Img';
import { uploadValidation } from '../../helpers/validators';
import { dummyRequest } from '../../helpers/common';
import { compareObject, parseTechnology } from '../../helpers/form';
import { BlockerComponent } from '../common/Blocker';

const titles: Record<FormModes, string> = {
  [FormModes.View]: 'Technology',
  [FormModes.Edit]: 'Edit Technology',
  [FormModes.Add]: 'Add Technology',
};

interface IProps {
  mode: FormModes;
  loading?: boolean;
  item?: Technology;
  addTechnology?: (params: AddTechnologyFnParams) => Promise<void>;
  editTechnology?: (params: EditTechnologyFnParams) => Promise<void>;
  deleteTechnology?: (params: DeleteTechnologyParams) => Promise<void>;
}

export const TechnologyForm: React.FC<IProps> = ({
  mode,
  item,
  loading,
  addTechnology,
  editTechnology,
  deleteTechnology,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState<UploadFile | undefined>(
    item?.logo?.file.url ? { uid: '1', name: '', url: item.logo.file.url } : undefined
  );
  const [isBlocked, setBlocked] = useState(false);
  const parseData = useMemo(() => parseTechnology(item), [item]);

  const onBack = () => {
    navigate(routes.technologies);
  };
  const goToEditMode = () => {
    navigate(`${routes.editTechnology}/${item?.objectId}`);
  };
  const goToViewMode = () => {
    navigate(`${routes.viewTechnology}/${item?.objectId}`);
  };
  const goToList = () => {
    navigate(routes.technologies);
  };
  const onAdd = (values: { name: string; logo?: LoadFile; showOnMainPage?: boolean; url?: string }) => {
    const { name, logo, showOnMainPage, url } = values;

    addTechnology?.({ name, logo, showOnMainPage, url }).then(goToList);
  };
  const onUpdate = (values: {
    name: string;
    logo?: { id?: string; file: LoadFile };
    showOnMainPage?: boolean;
    url?: string;
  }) => {
    const id = item?.objectId;
    if (!id) return;

    const { name, logo, showOnMainPage, url } = values;

    editTechnology?.({ id, name, logo, showOnMainPage, url })
      .then(() => setBlocked(false))
      .then(goToList);
  };
  const onDelete = () => {
    const id = item?.objectId;

    if (id)
      confirm(confirmDelete('technology'), async () => {
        await deleteTechnology?.({ id });
        onBack();
      });
  };

  const onFinish = (values: any) => {
    const fileIsDeleted = values.logo?.file?.status === FileStatus.removed;
    const file = fileIsDeleted ? null : values.logo?.file?.originFileObj;
    delete file?.uid;

    if (mode === FormModes.Add) return onAdd({ ...values, logo: file });
    if (mode === FormModes.Edit) return onUpdate({ ...values, logo: { file, id: item?.logo?.id } });
  };

  const setLogoFile = (info: UploadChangeParam<UploadFile>) => {
    return info.fileList.length ? setLogo({ ...info.file, status: 'done' }) : setLogo(undefined);
  };

  const extra: Array<ReactNode> =
    mode === FormModes.View
      ? [
          <EditButton key={Math.random()} onClick={goToEditMode} />,
          <DeleteButton key={Math.random()} onClick={onDelete} />,
        ]
      : [];

  const disabled = mode === FormModes.View;
  const onCompare = () => {
    const value = !compareObject(form.getFieldsValue(), parseTechnology(item));
    setBlocked((prev) => (prev === value ? prev : value));
  };

  useEffect(() => {
    const url = item?.logo?.file.url;
    if (url) setLogo({ uid: url, url, name: '' });
  }, [item]);

  return (
    <ViewContainer
      loading={loading}
      onBack={mode === FormModes.Edit ? goToViewMode : onBack}
      extra={extra}
      title={titles[mode]}
    >
      {mode === FormModes.Edit && <BlockerComponent isBlocked={isBlocked} form={form} object={parseData} />}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onCompare}
        initialValues={{
          name: item?.name,
          showOnMainPage: item?.showOnMainPage,
          url: item?.url,
        }}
      >
        <Form.Item
          label="Logo"
          name="logo"
          rules={
            !logo
              ? [
                  {
                    required: true,
                    message: 'Please input logo!',
                  },
                ]
              : undefined
          }
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={logo ? [logo] : []}
            showUploadList
            onChange={setLogoFile}
            disabled={disabled}
            customRequest={dummyRequest}
            beforeUpload={uploadValidation}
          >
            {!logo && disabled ? (
              <Img />
            ) : (
              !logo && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('Name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label="Link"
          name="url"
          rules={[
            {
              type: 'url',
              message: 'This field must be a valid url.',
            },
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item valuePropName="checked" name="showOnMainPage">
          <Checkbox disabled={disabled} name="showOnMainPage">
            Show on Main Page
          </Checkbox>
        </Form.Item>

        {mode === FormModes.Edit && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button onClick={goToViewMode}>Cancel</Button>
              <Br hrIndent={8} />
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
        {mode === FormModes.Add && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button htmlType="submit" type="primary">
                Add
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
      </Form>
    </ViewContainer>
  );
};
