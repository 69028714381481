import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { ViewContainer } from '../common/ViewContainer';
import { routes } from '../../constants/routes';
import { FormModes } from '../../constants/common';
import { AlignedBlock } from '../common/AlignedBlock';
import { DeleteButton, EditButton } from '../common/ActionsButtons';
import { Br } from '../common/Br';
import { Partner } from '../../types/partners';
import { Upload } from '../common/Upload';
import { AddPartnerType, EditPartnerType } from '../../hooks/partners';
import { FileStatus } from '../../constants/file';
import { DeletePartnerParams } from '../../queries/partners';
import { confirm } from '../common/Modals';
import { confirmDelete } from '../../constants/modals';
import { Img } from '../common/Img';
import { uploadValidation } from '../../helpers/validators';
import { dummyRequest } from '../../helpers/common';
import { PartnerSections } from './PartnerSections';
import { RearrangeData } from '../../hooks/list';
import { AddSectionFnParams } from '../../hooks/sections';
import { DeleteSectionParams } from '../../queries/sections';
import { Section } from '../../types/sections';
import { HTMLEditor } from '../Editor';
import { BlockerComponent } from '../common/Blocker';
import { compareObject, parsePartner } from '../../helpers/form';
import { ColorPicker } from '../common/ColorPicker';

const titles: Record<FormModes, string> = {
  [FormModes.View]: 'Partner',
  [FormModes.Edit]: 'Edit Partner',
  [FormModes.Add]: 'Add Partner',
};

interface IProps {
  mode: FormModes;
  item?: Partner;
  loading?: boolean;
  addPartner?: (params: AddPartnerType) => Promise<void>;
  deletePartner?: (params: DeletePartnerParams) => Promise<void>;
  editPartner?: (params: EditPartnerType) => Promise<void>;
  addSection?: (params: AddSectionFnParams) => Promise<void>;
  editSection?: (id: string, data: string) => Promise<void>;
  deleteSection?: (params: DeleteSectionParams) => Promise<void>;
  rearrangeData?: RearrangeData<Section>;
}

export const PartnerForm: React.FC<IProps> = ({
  mode,
  item,
  addPartner,
  deletePartner,
  editPartner,
  loading,
  addSection,
  editSection,
  deleteSection,
  rearrangeData,
}) => {
  const formRef = React.useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [logo, setLogo] = useState<UploadFile | undefined>(
    item?.logo?.file.url ? { uid: '1', name: '', url: item.logo.file.url } : undefined
  );
  const [isBlocked, setBlocked] = useState(false);
  const [titleColor, setColor] = useState('');
  const parseData = useMemo(() => parsePartner(item), [item]);

  const onBack = () => {
    navigate(routes.partners);
  };
  const goToViewMode = () => {
    navigate(`${routes.viewPartner}/${item?.objectId}`);
  };
  const goToList = () => {
    navigate(routes.partners);
  };

  const onChange = (descr: string) => {
    formRef.current?.setFieldsValue({ descr });
  };

  const onCreate = (values: AddPartnerType) => {
    addPartner?.(values).then(goToList);
  };
  const onSave = (values: EditPartnerType) => {
    const id = item?.objectId;
    if (!id) return;

    editPartner?.({ id, ...values })
      .then(() => setBlocked(false))
      .then(goToViewMode);
  };
  const onFinish = (values: any) => {
    const fileIsDeleted = values.logo?.file?.status === FileStatus.removed;
    const file = fileIsDeleted ? null : values.logo?.file?.originFileObj;
    delete file?.uid;

    if (mode === FormModes.Add) return onCreate({ ...values, titleColor, logo: file });
    if (mode === FormModes.Edit) return onSave({ ...values, titleColor, logo: { file, id: item?.logo?.id } });
  };
  const goToEditMode = () => {
    navigate(`${routes.editPartner}/${item?.objectId}`);
  };
  const onCancel = () => {
    goToViewMode();
  };
  const onDelete = () => {
    const id = item?.objectId;

    if (id)
      confirm(confirmDelete('partner'), async () => {
        await deletePartner?.({ id });
        onBack();
      });
  };
  const setLogoFile = (info: UploadChangeParam<UploadFile>) => {
    return info.fileList.length ? setLogo({ ...info.file, status: 'done' }) : setLogo(undefined);
  };
  const setTitleColor = (color: string) => setColor(color);

  const extra: Array<ReactNode> =
    mode === FormModes.View
      ? [
          <EditButton key={Math.random()} onClick={goToEditMode} />,
          <DeleteButton key={Math.random()} onClick={onDelete} />,
        ]
      : [];
  const onCompare = () => {
    const value = !compareObject(form.getFieldsValue(), parseData);
    setBlocked((prev) => (prev === value ? prev : value));
  };
  useEffect(() => {
    const url = item?.logo?.file.url;
    if (url) setLogo({ uid: url, url, name: '' });
    if (item?.titleColor) setColor(item.titleColor);
  }, [item]);

  const disabled = mode === FormModes.View;

  return (
    <ViewContainer
      loading={loading}
      onBack={mode === FormModes.Edit ? onCancel : onBack}
      extra={extra}
      title={titles[mode]}
    >
      {mode === FormModes.Edit && <BlockerComponent isBlocked={isBlocked} form={form} object={parseData} />}
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onCompare}
        initialValues={{
          name: item?.name,
          showOnMainPage: item?.showOnMainPage,
          website: item?.website,
          alias: item?.alias,
          descr: item?.descr,
        }}
      >
        <Form.Item
          label="Logo"
          name="logo"
          rules={
            !logo
              ? [
                  {
                    required: true,
                    message: 'Please input logo!',
                  },
                ]
              : undefined
          }
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            fileList={logo ? [logo] : []}
            showUploadList
            onChange={setLogoFile}
            disabled={disabled}
            name="logo"
            customRequest={dummyRequest}
            beforeUpload={uploadValidation}
          >
            {!logo && disabled ? (
              <Img />
            ) : (
              !logo && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 1) {
                  return Promise.resolve();
                }
                if (value.length <= 1) {
                  return Promise.reject(new Error('Name must be at least 2 characters long.'));
                }
              },
            }),
          ]}
        >
          <Input disabled={disabled} name="name" />
        </Form.Item>
        <Form.Item label="Description" name="descr">
          <HTMLEditor saveContent={onChange} content={item?.descr} disabled={disabled} />
        </Form.Item>
        {(mode === FormModes.Edit || mode === FormModes.View) && (
          <Form.Item
            label="Alias"
            name="alias"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (!getFieldValue('alias').match('^[a-zA-Z0-9_.-]*$')) {
                    return Promise.reject(new Error('Please fill correct alias (letters, numbers, dashes)'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input disabled={disabled} name="alias" />
          </Form.Item>
        )}
        <Form.Item label="Website" name="website">
          <Input disabled={disabled} name="website" />
        </Form.Item>

        <Form.Item valuePropName="checked" name="showOnMainPage">
          <Checkbox disabled={disabled} name="showOnMainPage">
            Show on Main Page
          </Checkbox>
        </Form.Item>

        <Form.Item label="Title Color">
          <ColorPicker color={titleColor} onChange={setTitleColor} disabled={disabled} />
        </Form.Item>
        {(mode === FormModes.Edit || mode === FormModes.View) && (
          <PartnerSections
            addSection={addSection}
            editSection={editSection}
            deleteSection={deleteSection}
            disabled={mode === FormModes.View}
            rearrangeData={rearrangeData}
            loading={loading}
            titleColor={titleColor}
          />
        )}

        {mode === FormModes.Edit && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button onClick={onCancel}>Cancel</Button>
              <Br hrIndent={8} />
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
        {mode === FormModes.Add && (
          <Form.Item>
            <AlignedBlock variant="end">
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </AlignedBlock>
          </Form.Item>
        )}
      </Form>
    </ViewContainer>
  );
};
