import { gql } from '@apollo/react-hooks';
import { AppFileFragment } from './appfile';

export const TechnologyFragment = gql`
  fragment TechnologyFragment on Technology {
    id
    objectId
    createdAt
    updatedAt
    name
    url
    logo {
      ...AppFileFragment
    }
    showOnMainPage
  }
  ${AppFileFragment}
`;
