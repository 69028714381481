import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Auth } from '../../components/Auth';
import { useAuth, useChangePassword, useResetPassword } from '../../hooks/auth';
import { RestorePassword } from '../../components/Auth/RestorePassword';
import { routes } from '../../constants/routes';
import { ChangePassword } from '../../components/Auth/ChangePassword';

export const AuthContainer: React.FC = () => {
  const { login, rolesData, loginData } = useAuth();
  const { loading, onSubmit } = useResetPassword();
  const { loading: changeLoading, onSubmit: onChange } = useChangePassword();
  const isLoading = rolesData.loading || loginData.loading || false;

  return (
    <Routes>
      <Route path={routes.any} element={<Auth login={login} loading={isLoading} />} />
      <Route path={routes.recovery} element={<RestorePassword loading={loading} onSubmit={onSubmit} />} />
      <Route path={routes.change} element={<ChangePassword loading={changeLoading} onSubmit={onChange} />} />
    </Routes>
  );
};
