import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { AuthData, roles } from '../types/auth';
import {
  ChangePasswordPayload,
  ChangePasswordQuery,
  ChangePasswordResponse,
  GetRolesByUserIdQuery,
  GetRolesByUserIdResponse,
  GetRolesByUserIdVariables,
  LoginQuery,
  LoginResponse,
  LoginVariables,
  LogoutQuery,
  ResetPasswordPayload,
  ResetPasswordQuery,
  ResetPasswordResponse,
  ViewerQuery,
  ViewerResponse,
} from '../queries/auth';
import { sessionState } from '../states/sessionState';

export function useFetchSession() {
  const [session, setSession] = useRecoilState(sessionState);

  const { loading, data } = useQuery<ViewerResponse>(ViewerQuery);

  useEffect(() => {
    if (!data) return;
    setSession(data.viewer);
  }, [data]);

  return { session, loading };
}

export const useLogout = () => {
  const [doLogout] = useMutation(LogoutQuery);
  const setSession = useSetRecoilState(sessionState);

  const logout = async () => {
    await doLogout();
    localStorage.removeItem('token');
    setSession(null);
  };

  return { logout };
};

export const useAuth = () => {
  const [loginQuery, _loginData] = useMutation<LoginResponse, LoginVariables>(LoginQuery);
  const [getRolesQuery, _rolesData] = useLazyQuery<GetRolesByUserIdResponse, GetRolesByUserIdVariables>(
    GetRolesByUserIdQuery
  );
  const setSession = useSetRecoilState(sessionState);
  const { logout: doLogout } = useLogout();

  const login = async (data: AuthData) => {
    const { username, password } = data;
    if (!username || !password) return;
    try {
      const { data: loginData } = await loginQuery({ variables: { username, password } });
      if (!loginData?.logIn?.viewer) throw new Error("Login Data hasn't been received!");

      const { user, sessionToken } = loginData.logIn.viewer;

      localStorage.setItem('token', sessionToken);

      const { data: rolesData } = await getRolesQuery({ variables: { id: user.objectId } });
      if (!rolesData?.roles?.edges?.length) throw new Error("During Login The Role hasn't been received!");

      const isAdmin = rolesData?.roles.edges.some((item) => {
        return item.node.name === roles.admin;
      });

      if (!isAdmin) {
        await doLogout();
        return message.error('Only admin can login!');
      }

      setSession(loginData.logIn.viewer);
    } catch (e) {
      localStorage.removeItem('token');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      message.error(typeof e === 'object' ? e.message : e);
    }
  };

  return { login, loginData: _loginData, rolesData: _rolesData };
};

export const useResetPassword = () => {
  const [reset, { loading }] = useMutation<ResetPasswordResponse, ResetPasswordPayload>(ResetPasswordQuery);

  const navigation = useNavigate();

  const onSubmit = async (email: string) => {
    if (loading) return;
    try {
      const { data } = await reset({ variables: { email } });
      if (data?.resetPassword?.ok && !loading) {
        message.success('Message sent successfully.');
        navigation('/');
      }
    } catch (err) {
      message.error('Something went wrong. Try again!');
    }
  };
  return { loading, onSubmit };
};

export const useChangePassword = () => {
  const [change, { loading }] = useMutation<ChangePasswordResponse, ChangePasswordPayload>(ChangePasswordQuery);

  const navigation = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const id = params.get('id');
  const token = params.get('token');

  const onSubmit = async (password: string) => {
    if (!id || !token || loading) return;

    try {
      const { data } = await change({
        variables: {
          id,
          password,
          token,
        },
      });

      if (data?.changePassword?.ok && !loading) {
        message.success('Password changed successfully.');
        navigation('/');
      }
    } catch (error) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { onSubmit, loading };
};
