import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import {
  GetTeamsRes,
  GetTeamsQuery,
  GetTeamsParams,
  AddTeamRes,
  AddTeamParams,
  AddTeamQuery,
  GetTeamRes,
  GetTeamParams,
  GetTeamQuery,
  DeleteTeamRes,
  DeleteTeamParams,
  DeleteTeamQuery,
  EditTeamRes,
  EditTeamParams,
  EditTeamQuery,
} from '../queries/teams';
import { order } from '../constants/common';
import {
  CreateAppFileQuery,
  CreateFileParams,
  CreateFileRes,
  UpdateAppFileQuery,
  UpdateFileParams,
  UpdateFileRes,
} from '../queries/appfile';
import { AppFile, LoadFile } from '../types/common';
import { GQLAppFilePointerInput } from '../graphql.schema';
import { changeFileName } from '../helpers/common';

export const useGetTeamsList = (value?: string) => {
  const where = value ? { where: { firstName: { equalTo: value } } } : null;
  const { data, ...otherData } = useQuery<GetTeamsRes, GetTeamsParams>(GetTeamsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      order: [order.layOut],
      ...where,
    },
  });

  const _data = useMemo(() => data?.teams.edges.map((el) => el.node), [data]);

  return { data: _data, ...otherData };
};

export type AddTeamType = Partial<Omit<AddTeamParams, 'avatar'> & { avatar: LoadFile }>;

export const useAddTeam = (options?: { cb?: (id: string) => void }) => {
  const { cb } = options || {};
  const [add, otherData] = useMutation<AddTeamRes, AddTeamParams>(AddTeamQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);

  const addTeam = async (_params: AddTeamType) => {
    const { avatar, ...params } = _params;
    try {
      let avatarInfo: AppFile | undefined;

      if (avatar) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(avatar as File),
          } as GQLAppFilePointerInput,
        });
        avatarInfo = response.data?.createAppFile.appFile;
      }

      const { data } = await add({
        variables: {
          ...params,
          ...(avatarInfo?.objectId
            ? {
                avatar: {
                  link: avatarInfo.objectId,
                },
              }
            : {}),
        },
      });
      const id = data?.createTeam.team.objectId;
      if (id) cb?.(id);
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { createTeam: addTeam, ...otherData };
};

export const useGetTeam = () => {
  const { id } = useParams<{ id: string }>();
  const { data, ...otherData } = useQuery<GetTeamRes, GetTeamParams>(GetTeamQuery, {
    variables: { id: id || '' },
  });

  return { data: data?.team, ...otherData };
};

export const useDeleteTeam = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [remove, otherData] = useMutation<DeleteTeamRes, DeleteTeamParams>(DeleteTeamQuery);

  const deleteTeam = async (params: DeleteTeamParams) => {
    try {
      await remove({ variables: params });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { deleteTeam, otherData };
};

export type EditTeamType = Omit<EditTeamParams, 'avatar'> & { avatar?: { file: LoadFile; id?: string } };

export const useEditTeam = (options: { onsuccessCb?: () => void }) => {
  const { onsuccessCb } = options;
  const [edit, otherData] = useMutation<EditTeamRes, EditTeamParams>(EditTeamQuery);
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);
  const [updateAppFile] = useMutation<UpdateFileRes, UpdateFileParams>(UpdateAppFileQuery);

  const editTeam = async (_params: EditTeamType) => {
    const { avatar, ...params } = _params;
    const { id, file } = avatar || {};

    try {
      let avatarInfo: AppFile | undefined;

      if (file && !id) {
        const response = await createAppFile({
          variables: {
            upload: changeFileName(file),
          } as GQLAppFilePointerInput,
        });

        avatarInfo = response.data?.createAppFile.appFile;
      } else if (file && id) {
        const response = await updateAppFile({
          variables: {
            upload: changeFileName(file),
            id,
          },
        });

        avatarInfo = response.data?.UpdateAppFile?.appFile;
      }

      await edit({
        variables: {
          ...params,
          ...(avatarInfo?.objectId
            ? {
                avatar: {
                  link: avatarInfo?.objectId,
                },
              }
            : {}),
        },
      });
      onsuccessCb?.();
    } catch (e) {
      message.error('Something went wrong. Try again!');
    }
  };

  return { editTeam, ...otherData };
};
