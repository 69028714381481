import React from 'react';
import { RecoilRoot } from 'recoil';
import { LayoutContainer } from './containers/LayoutContainer';
import { ApolloProvider } from './ApolloProvider';
import { GlobalStyles } from './styles';

function App() {
  return (
    <RecoilRoot>
      <ApolloProvider>
        <GlobalStyles />
        <LayoutContainer />
      </ApolloProvider>
    </RecoilRoot>
  );
}

export default App;
