import React from 'react';
import { FormModes } from '../../constants/common';
import { TeammateForm } from '../../components/Team';
import { useDeleteTeam, useGetTeam } from '../../hooks/teams';

export const ViewTeammate = () => {
  const { data, refetch, loading } = useGetTeam();

  const { deleteTeam } = useDeleteTeam({
    onsuccessCb: () => refetch(),
  });

  return <TeammateForm loading={loading} deleteTeam={deleteTeam} item={data} mode={FormModes.View} />;
};
