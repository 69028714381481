import { gql } from '@apollo/react-hooks';
import { AppFileFragment } from './appfile';

export const TeamFragment = gql`
  fragment TeamFragment on Team {
    objectId
    firstName
    lastName
    email
    phone
    birthDate
    showInContacts
    showInTeam
    avatar {
      ...AppFileFragment
    }
    position
    qr
  }
  ${AppFileFragment}
`;
