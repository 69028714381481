import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Select } from 'antd';
import { AlignedBlock } from '../common/AlignedBlock';
import { CreateFileRes, CreateFileParams, CreateAppFileQuery } from '../../queries/appfile';
import { changeFileName, filterPB } from '../../helpers/common';
import { GQLAppFilePointerInput } from '../../graphql.schema';
import { EditorWrapper } from './styles';
import { FroalaEditor } from './FroalaEditor';
import { layouts } from '../../constants/editor';
import { useRemovePb } from '../../hooks/sections';

type HTMLEditorProps = {
  content?: string;
  layoutType?: string;
  saveContent?: (data: string, layoutType?: string) => void;
  disabled?: boolean;
  titleColor?: string;
};

export const HTMLEditor: React.FC<HTMLEditorProps> = ({ content, saveContent, disabled, layoutType, titleColor }) => {
  const [createAppFile] = useMutation<CreateFileRes, CreateFileParams>(CreateAppFileQuery);
  const [model, setModel] = useState(content || '');
  const [select, setSelect] = useState(layoutType || layouts[0]);
  const [uploadedImages, setUploadedImages] = useState<any>([]);

  const onSaveContent = () => {
    const withoutPB = filterPB(model);
    saveContent?.(withoutPB, select);
  };

  const onSelect = (el: string) => {
    setSelect(el);
  };

  const uploadImageCallBack = async (file: Blob | MediaSource): Promise<{ data: { link: string } }> => {
    const response = await createAppFile({
      variables: {
        upload: changeFileName(file as File),
      } as GQLAppFilePointerInput,
    });
    const image = response.data?.createAppFile.appFile.file.url;

    const imageObject = {
      file,
      localSrc: image,
    };
    setUploadedImages(uploadedImages.push(imageObject));

    return {
      data: {
        link: imageObject.localSrc || '',
      },
    };
  };

  const onModelChange = (model: string) => {
    setModel(model);
  };

  useEffect(() => {
    if (content) {
      setModel(content);
    }
  }, [!!content]);

  useRemovePb(model);
  return (
    <EditorWrapper>
      <Select
        defaultValue={select}
        onSelect={onSelect}
        placeholder="Select a layout type"
        optionFilterProp="children"
        disabled={disabled}
      >
        {layouts?.map((el) => (
          <Select.Option key={el} value={el}>
            {el}
          </Select.Option>
        ))}
      </Select>
      <FroalaEditor
        disabled={disabled}
        model={model}
        onModelChange={onModelChange}
        onUpload={uploadImageCallBack}
        titleColor={titleColor}
      />
      {!disabled && (
        <AlignedBlock variant="end">
          <Button type="primary" onClick={onSaveContent} disabled={disabled}>
            Save content
          </Button>
        </AlignedBlock>
      )}
    </EditorWrapper>
  );
};
