import React from 'react';
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import 'froala-editor/js/froala_editor.pkgd.min.js';
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import 'froala-editor/js/plugins.pkgd.min.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_style.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_editor.pkgd.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/plugins.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { FroalaWrapper } from './styles';

import { editorConfig } from '../../constants/editor';

interface IEditor {
  onModelChange: (model: string) => void;
  model?: string;
  onUpload?: (file: Blob | MediaSource) => Promise<{ data: { link: string } }>;
  disabled?: boolean;
  titleColor?: string;
}

export const FroalaEditor: React.FC<IEditor> = ({ onModelChange, model = '', onUpload, disabled, titleColor }) => {
  const config = onUpload
    ? {
        ...editorConfig,
        pluginsEnabled: [...editorConfig.pluginsEnabled, 'image'],
        events: {
          'image.beforeUpload': function (response: Array<File>) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: any = this;
            onUpload(response[0]).then((res) => {
              return editor.image.insert(res.data.link, null, null, editor.image.get());
            });
            return false;
          },
          'video.beforeUpload': function (response: Array<File>) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: any = this;
            onUpload(response[0]).then((res) => {
              return editor.video.insert(
                `<iframe src=${res.data.link} frameborder="0" allowfullscreen="" class="fr-draggable"></iframe>`
              );
            });
            return false;
          },
        },
      }
    : editorConfig;
  return (
    <FroalaWrapper $disabled={disabled} titleColor={titleColor}>
      <FroalaEditorComponent tag="textarea" onModelChange={onModelChange} model={model} config={config} />
    </FroalaWrapper>
  );
};
