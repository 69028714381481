import { Reducer, useEffect, useReducer, useState } from 'react';
import { MutationResult, useMutation } from '@apollo/react-hooks';
import { ItemType, ListActions, ListModes } from '../constants/common';
import { ListObject } from '../types/common';
import { ChangeOrderParams, ChangeOrderQuery, ChangeOrderRes } from '../queries/list';

interface Params {
  itemType: ItemType;
  items?: Array<ListObject>;
  onsaveCb?: () => void;
}

interface Action {
  type: ListActions;
  payload: {
    id?: string;
    list?: Array<ListObject>;
  };
}

const reducer = (state: Array<ListObject>, action: Action) => {
  const { type, payload } = action;
  const { id, list = [] } = payload;

  const currentIdx = id ? state.findIndex((el) => el.objectId === id) : null;

  const copyState = [...state];

  switch (type) {
    case ListActions.MoveUp:
      if (currentIdx === 0 || currentIdx === null) return state;

      [copyState[currentIdx], copyState[currentIdx - 1]] = [copyState[currentIdx - 1], copyState[currentIdx]];

      return copyState;

    case ListActions.MoveDown:
      if (currentIdx === null || state.length - currentIdx <= 1) return state;

      [copyState[currentIdx + 1], copyState[currentIdx]] = [copyState[currentIdx], copyState[currentIdx + 1]];

      return copyState;

    case ListActions.SetData:
      return list;

    default:
      return state;
  }
};

export interface RearrangeData<T> {
  mode: ListModes;
  enableEditMode: () => void;
  disableEditMode: () => void;
  items: T[];
  moveUp: (id: string) => void;
  moveDown: (id: string) => void;
  saveOrder: () => Promise<void>;
  orderData: MutationResult<ChangeOrderRes>;
}

export const useRearrangeList = <T extends ListObject>(params: Params): RearrangeData<T> => {
  const { itemType, items, onsaveCb } = params;

  const [mode, setMode] = useState<ListModes>(ListModes.View);
  const [state, dispatch] = useReducer<Reducer<Array<ListObject>, Action>>(reducer, []);
  const [saveRange, orderData] = useMutation<ChangeOrderRes, ChangeOrderParams>(ChangeOrderQuery);

  const enableEditMode = () => {
    setMode(ListModes.EditOrder);
  };
  const disableEditMode = () => {
    setMode(ListModes.View);
  };

  const moveUp = (id: string) => {
    dispatch({
      type: ListActions.MoveUp,
      payload: {
        id,
      },
    });
  };
  const moveDown = (id: string) => {
    dispatch({
      type: ListActions.MoveDown,
      payload: {
        id,
      },
    });
  };
  const saveOrder = async () => {
    const list = state.map((el) => el.objectId).filter((el) => !!el) as Array<string>;

    await saveRange({
      variables: {
        list,
        className: itemType,
      },
    }).then(() => onsaveCb?.());
  };

  useEffect(() => {
    dispatch({ type: ListActions.SetData, payload: { list: items } });
  }, [items]);

  useEffect(() => {
    if (mode === ListModes.View && !!items?.length) dispatch({ type: ListActions.SetData, payload: { list: items } });
  }, [mode]);

  return {
    mode,
    enableEditMode,
    disableEditMode,
    items: state as Array<T>,
    moveUp,
    moveDown,
    saveOrder,
    orderData,
  };
};
