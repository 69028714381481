import React from 'react';
import { TechnologyForm } from '../../components/Technologies/TechnologyForm';
import { FormModes } from '../../constants/common';
import { useEditTechnology, useGetTechnology } from '../../hooks/technologies';

export const EditTechnology = () => {
  const { data, loading } = useGetTechnology();
  const { editTechnology } = useEditTechnology();

  return <TechnologyForm editTechnology={editTechnology} mode={FormModes.Edit} item={data} loading={loading} />;
};
