import { Layout, Menu as AntMenu, Typography } from 'antd';
import styled from 'styled-components';

const { Content: AntContent } = Layout;

export const LayoutContainer = styled(Layout)`
  min-height: 100vh;
  background: none;
  .ant-layout-sider-zero-width-trigger {
    top: 0;
  }
`;

export const Menu = styled(AntMenu)`
  position: sticky;
  top: 0;
`;

export const Content = styled(AntContent)`
  padding: 40px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

export const Username = styled(Typography.Title).attrs({
  level: 4,
})`
  margin: 0 0 0 16px !important;
  color: #fff !important;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
  font-size: 18px !important;
`;

export const SidebarTitle = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 24px;
  align-items: center;
  color: #fff !important;
  text-align: center;
  height: 64px;
`;
