import { User } from './user';
import { ParseObject } from './parse';

export interface Session {
  sessionToken: string;
  user: User;
}

export interface AuthData {
  username: string;
  password: string;
}

export interface Role extends ParseObject {
  name: string;
}

export enum roles {
  admin = 'admin',
}
